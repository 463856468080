import NavProvider from '../context/NavContext';
import NavItem from './NavItem';
import ImportFont from './ImportFont';
import Variables from './Variables';
import Icons from './Icons';
import LogInArea from './LogInArea';
import Uploads from './Projects/Uploads';
import Help from './Help';
import SavedProjects from './Projects/SavedProjects';
import ProjectName from './Projects/ProjectName';
import File from './File/File';
import '../../stylesheets/Navbar.scss';

const Navbar = () => {

    return (
        <div className="Navbar">
            <NavProvider>
                <div className="nav-left-side">
                    <NavItem label="File">
                        <File />
                    </NavItem>
                    <NavItem label="Fonts">
                        <ImportFont />
                    </NavItem>
                    <NavItem label="Variables">
                        <Variables />
                    </NavItem>
                    <NavItem label="Icons">
                        <Icons/>
                    </NavItem>
                    <NavItem label="Uploads">
                        <Uploads />
                    </NavItem>
                    <NavItem label="Projects">
                        <SavedProjects />
                    </NavItem>
                    <NavItem label="Help">
                        <Help />
                    </NavItem>
                </div>
                <div className="nav-right-side">
                    <ProjectName />
                    <LogInArea />
                </div>
            </NavProvider>
        </div>
    )
}

export default Navbar;