import { useContext } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import clone from '../../utils/_clone';

const variablefullValueRegex = /\$(.*?)['"`](.*?)['"`];/gs;
const variableNameRegex = /\$(.*?)['"`](.*?)['"`];/s;
const findVariableRegex = /\$[A-Za-z0-9]*/g;
const stringRegex = /['"`](.*?)['"`]/s;

const ScopedStylesheet = () => {
    const shapesContext = useContext(ShapesContext);

    const styles = clone(shapesContext.styles);
    const individualStyles = clone(shapesContext.individualStyles);

    const getVariables = () => {
        const allVariables = shapesContext.variables.match(variablefullValueRegex);
        const formattedVariables = [];
        if (allVariables) {
            for (let [index, variable] of allVariables.entries()) {
                const variableValue = variable.match(stringRegex);
                const variableName = variable.match(variableNameRegex);
                formattedVariables.push({name: '', value: ''});
                if (variableValue) {
                    formattedVariables[index].value = variableValue[1];
                }
                if (variableName) {
                    const cleanName = variableName[1].replace('=', '').trim();
                    formattedVariables[index].name = cleanName;
                }
            }
        }
        const cleanFormattedVariables = formattedVariables.filter(item => item.value && item.name);
        return cleanFormattedVariables;
    }

    const allVariables = getVariables();

    const adjustStyleObject = (styleObj) => {
        const classHasVariables = styleObj.style.match(findVariableRegex);
        if (classHasVariables) {
            for (let variable of classHasVariables) {
                const variableObj = allVariables.find(item => "$" + item.name ===  variable);
                if (variableObj) {
                    styleObj.style = styleObj.style.replace(variable, variableObj.value);
                }
            }
        }
        return styleObj;
    }

    const adjustedStyles = styles.map(styleObj => (adjustStyleObject(styleObj)));
    const adjustedIndividualStyles = individualStyles.map(styleObj => (adjustStyleObject(styleObj)));

    return (
        <style id="shape-area-styles">
            {`.shape-area-wrapper {
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif
            }`}
            {adjustedStyles.map(styleObj => (
                `.ShapeArea ${styleObj.classId} {
                    ${styleObj.style}
                }`
            ))}
            {adjustedIndividualStyles.map(styleObj => (
                `.ShapeArea ${styleObj.classId} {
                    ${styleObj.style}
                }`
            ))}
        </style>
    )
}

export default ScopedStylesheet;