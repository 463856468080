import { useContext, useState } from 'react';
import { ShapesContext } from '../../context/ShapesContext';
import { convertFontStylesheets } from '../../../utils/_download-fixes';
import domtoimage from 'dom-to-image';
import download from 'downloadjs';
import Loading from '../../Loading';

const DownloadProject = () => {
    const shapesContext = useContext(ShapesContext);
    const [loading, setLoading] = useState(false);

    const fontsToString = () => {
        const fonts = shapesContext.importedFonts;
        return fonts.map(font => (`
            <link rel="preconnect" href="https://fonts.gstatic.com">
            <link href="https://fonts.googleapis.com/css2?family=${font.replace(' ', '+')}&display=swap" rel="stylesheet" crossorigin="anonymous">
        `)).join('');
    }

    const handleDownload = async (extension) => {
        setLoading(true);
        const shapeAreaImage = document.querySelector('.shape-area-wrapper');
        const projectName = JSON.parse(localStorage.getItem('project-name')) || 'design';
        const styleSheet = document.querySelector('#shape-area-styles');
        if (extension === 'png') {
            convertFontStylesheets();
            shapeAreaImage.style.width = "auto";
            try {
                const image = await domtoimage.toPng(shapeAreaImage);
                download(image, projectName + '.png');
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
            shapeAreaImage.style.width = "100%";
        }
        if (extension === "svg") {
            // Not working for now
            // convertFontStylesheets();
            // shapeAreaImage.style.width = "auto";
            // try {
            //     const image = await domtoimage.toSvg(shapeAreaImage);
            //     console.log(image);
            //     //let fixedImage = image.replace('data:image/svg+xml;charset=utf-8,', '');
            //     //fixedImage = decodeURI(image);
            //     //console.log(fixedImage);
            //     //download(image, projectName + '.svg', 'image/svg+xml');
            // } catch (err) {
            //     console.error(err);
            // }
            // shapeAreaImage.style.width = "100%";
        }
        if (extension === "html") {
            try {
                const documentString = convertToHTMLDocument(shapeAreaImage.outerHTML, styleSheet.outerHTML, projectName);
                download(documentString, projectName + '.html', 'text/html');
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
        if (extension === "pdf") {
            try {
                const elementWindow = window.open('', 'PDF', 'height=650,width=900,top=100,left=150');
                const documentString = convertToHTMLDocument(shapeAreaImage.outerHTML, styleSheet.outerHTML, projectName);
                elementWindow.document.write(documentString);
                elementWindow.document.close(); // necessary for IE >= 10
                elementWindow.focus(); // necessary for IE >= 10*/
                elementWindow.addEventListener('load', () => {
                    elementWindow.print();
                });
                //elementWindow.close();
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
        setLoading(false);
    }

    const convertToHTMLDocument = (htmlString, styleString, projectName) => {
        // Use outerHTML for htmlString and styleString
        return `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>${projectName}</title>
                ${fontsToString()}
            </head>
            <body style="margin: 0; padding: 0;">
            ${styleString}
            <style id="other-styles">
                .ShapeArea {
                    line-height: 1.2;
                }
                .ShapeArea .Shape svg {
                    display: flex;
                }
            </style>
            <div class="ShapeArea">
            ${htmlString}
            </div>
            </body>
            </html>
        `;
    }

    return (
        <div className="DownloadProject">
            <div className="file-list-item" onClick={() => handleDownload('png')}>Download as PNG</div>
            <div className="file-list-item" onClick={() => handleDownload('html')}>Download as HTML</div>
            <div className="file-list-item" onClick={() => handleDownload('pdf')}>Print/Download as PDF</div>
            {loading &&
                <Loading />
            }
        </div>
    )
}

export default DownloadProject;