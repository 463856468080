import { useContext } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import Shape from './Shape';
import '../../stylesheets/ShapeArea.scss';

const ShapeArea = ({rightOffset}) => {
    const shapesContext = useContext(ShapesContext);

    return (
        <div className="ShapeArea" style={{paddingRight: rightOffset + "px"}}>
            <div className="shape-area-wrapper">
                {shapesContext.shapes.map(shape => {
                    if (!shape.parent && !shape.removed) {
                        return (<Shape key={shape.id} data={shape} />)
                    }
                })}
            </div>
        </div>
    )
}

export default ShapeArea;