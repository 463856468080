const Help = () => {

    return (
        <div className="Help overflowing-item">
            <h2 className="tutorial-label">Create an element</h2>
            <div className="explanation">
                Start your elements with a period, like this:
                <pre>.container<br></br>&#9;.navbar<br></br>&#9;.section</pre>
                Press tab to indent the element, or backspace to unindent. You can select multiple lines and indent with Tab, and can press Shift + Tab to unindent selected lines. This will make it a child of the element above.
                You can chain class names of elements together:
                <pre>.container<br></br>&#9;.navbar<br></br>&#9;.section.blue<br></br>&#9;.section.green.horizontal<br></br>
                </pre>
            </div>
            <h2 className="tutorial-label">Add text to your element</h2>
            <div className="explanation">
                You can add text to your element with the text rule. For example:
                <pre>text: This is some text;</pre>
                HTML is also supported:
                <pre>text: Sometimes you need a {`</br>`} break;</pre>
                Your text can also span multiple lines:
                <pre>text: <br></br>This text spans<br></br>multiple lines;</pre>
            </div>
            <h2 className="tutorial-label">Add an image to your element</h2>
            <div className="explanation">
                You can make the element into an image element by writing the img rule in your styling. For example:
                <pre>img: https://example.com/x.jpg;</pre>
                or you can use a background image in your styling:
                <pre>background-image: https://example.com/x.jpg;</pre>
                Note that you do not need to use url() for the background image. Also note that url() will not work, your background image must be formatted as shown.
                You can also upload images in the Uploads tab. If your image name is "image-name", you can use your image like so:
                <pre>img: image-name;</pre>
                or
                <pre>background-image: image-name;</pre>
            </div>
            <h2 className="tutorial-label">Using icons</h2>
            <div className="explanation">
                Using icons is straightforward:
                <ol>
                    <li>Click on the Icons tab.</li>
                    <li>Find the icon you want by either searching or scrolling.</li>
                    <li>Copy the icon code, such as <pre>icon: BsFillAlarmFill;</pre> to the styling area.</li>
                </ol>
            </div>
            <h2 className="tutorial-label">Using fonts</h2>
            <div className="explanation">
                You can use any font on Google Fonts.
                <ol>
                    <li>Click on the Fonts tab.</li>
                    <li>Click import font.</li>
                    <li>Copy/type the name of the font from Google Fonts and put it in the text box.</li>
                    <li>Press the import button.</li>
                    <li>The font code will show up on the font tab, such as <pre>font-family: 'Roboto';</pre> which you can then put in the styling area.</li>
                </ol>
            </div>
            <h2 className="tutorial-label">Using variables</h2>
            <div className="explanation">
                You can define a variable like so:
                <pre>$flex = "display: flex";</pre>
                You can then use the variable in your styles like this:
                <pre>$flex;</pre>
                Variables can be multile lines and even entire chunks of CSS:
                <pre>$center = <br></br>
                    "display: flex;<br></br>
                    align-items: center;<br></br>
                    justify-content: center";
                </pre>
                which could go in your styling like this:
                <pre>
                    text: Hello World;<br></br>
                    color: orange;<br></br>
                    $center;<br></br>
                </pre>
            </div>
            <h2 className="tutorial-label">Changing styles</h2>
            <div className="explanation">
                UltraCSS will save up to 20 deleted elements that contain styling.<br></br><br></br>You can import these styles and all other styles in the project into a class by selecting an element and clicking the "Import styles" icon next to the class name.<br></br><br></br>
                You can also copy, swap, and move between styles. If you have many elements with the same class name, it's recommended to use these tools rather than cutting and pasting large chunks of markup in the element editor area.
            </div>
        </div>
    )
}

export default Help;