import { useState, useEffect } from 'react';
import icons from '../../utils/_icons';
import { BsSearch } from 'react-icons/bs';

const numIcons = 12;

const Icons = () => {
    const [resultRange, setResultRange] = useState([0, numIcons]);
    const [searchResults, setSearchResults] = useState(Array.from(icons));
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchQuery = (e) => {
        setSearchQuery(e.target.value);
        setSearchResults(Array.from(icons).filter(item => item[0].toLowerCase().includes(e.target.value) ||  e.target.value.includes(item[0].toLowerCase())));
        setResultRange([0, numIcons]);
    }

    const handleLazyLoad = (e) => {
        e.preventDefault();
    }

    const handleWheel = (e) => {
        if (e.deltaY > 0) {
            setResultRange(oldValue => {
                const newValue = [...oldValue];
                newValue[0] = newValue[0] + (e.deltaY / 30);
                newValue[1] = newValue[0] + numIcons;
                if (newValue[1] > searchResults.length) {
                    newValue[0] = searchResults.length - numIcons;
                    newValue[1] = searchResults.length;
                }
                return newValue;
            }); 
        } else {
            setResultRange(oldValue => {
                const newValue = [...oldValue];
                newValue[0] = newValue[0] + (e.deltaY / 30);
                newValue[1] = newValue[0] + numIcons;

                if (newValue[0] < 0) {
                    newValue[0] = 0;
                    newValue[1] = numIcons;
                }
                return newValue;
            }); 
        }
    }

    return (
        <div className="Icons" onScroll={handleLazyLoad} onWheel={handleWheel}>
            <div className="icon-search-box-container">
                <input type="text" onChange={handleSearchQuery} value={searchQuery} className="search-box"/>
                <div className="search-box-icon"><BsSearch /></div>
            </div>
            <div className="search-results">
                {searchResults.slice(Math.round(resultRange[0]), Math.round(resultRange[1])).map(result => (
                    <div key={result[0]} className="result">
                        <div className="result-icon">{result[1]()}</div>
                        <div className="result-name">icon: {result[0]};</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Icons;