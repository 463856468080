import { useEffect } from 'react';
import useComponentVisible from "../hooks/useComponentVisible";
import AreYouSure from './AreYouSure';

const ActionSubmenu = ({message, onClick, children, resetComponentVisible, openFrom, absoluteRight, escapeMessage = false}) => {
    const {ref, isComponentVisible} = useComponentVisible(true);

    openFrom = openFrom || 'side';

    useEffect(() => {
        if (!isComponentVisible) {
            resetComponentVisible(false);
        }
        if (ref && ref.current) {
            const element = ref.current;
            const elementParentBox = element.parentElement.getBoundingClientRect();
            if (openFrom.includes('side')) {
                if (!element.style.top) {
                    element.style.top = elementParentBox.top + "px";
                }
                if (!element.style.left) {
                    element.style.left = elementParentBox.left + elementParentBox.width + "px";
                }
            }
            if (openFrom.includes('bottom')) {
                if (!element.style.top) {
                    element.style.top = elementParentBox.top + elementParentBox.height + "px";
                }
            }
            if (openFrom.includes('right') && openFrom.includes('absolute')) {
                absoluteRight = absoluteRight || 0;
                if (!element.style.right) {
                    element.style.right = absoluteRight + "px";
                }
            }
            element.style.visibility = "visible";
        }
    });

    return (
        <div className="ActionSubmenu" ref={ref}>
            {isComponentVisible &&
                <>
                    {message &&
                        <div className="are-you-sure-wrapper">
                            <AreYouSure 
                                message={message}
                                onConfirm={onClick}
                                onCancel={() => resetComponentVisible(false)}
                                escapeMessage={escapeMessage}
                            />
                        </div>
                    }
                    {children}
                </>
            }
        </div>
    )
}

export default ActionSubmenu;