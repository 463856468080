const acceptableCharacters = /^[a-zA-Z-_.\s0-9]+$/;

const handleInputKeyDown = (e) => {
    const matchCharacter = e.key.match(acceptableCharacters);
    if (e.key !== "Backspace" && !e.metaKey && !e.ctrlKey && e.target.value.length > 35 || !matchCharacter) {
        e.preventDefault();
        return;
    }
}

export default handleInputKeyDown;