import { useContext, useRef } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import CodeEditor from '../CodeEditor';
import StyleArea from './StyleArea';
import { adjustBackgroundImages } from '../../utils/_styles';
import '../../stylesheets/LayerPanel.scss';

import clone from '../../utils/_clone';
import ID from '../../utils/_id';
import { getRandomColor, numberOfTabs, findShapeParent, findShapeDirectChildren, removeDuplicates} from '../../utils/_code-editor';

const LayerPanel = () => {
    const selectionRef = useRef(null);
    const shapesContext = useContext(ShapesContext);

    const methods = {
        //
        // Gets a saved selection
        //
        getSavedSelection: (data) => {
            selectionRef.current = data;
        },
        //
        // Checks if the old item at a shared index is the same
        //
        oldItemAtIndexIsTheSame: (array, index, newItemClassId) => {
            if (!array[index]) return false;
            if (array[index].classId === newItemClassId) return true;
            return false;
        },
        //
        // Removes removed styles past threshold, adjusts other styles
        //
        adjustRemovedStyles: (oldStyles, adjustedStyles) => {
            const removedStyles = methods.getRemovedStyles(oldStyles, adjustedStyles);
            adjustedStyles = adjustedStyles.concat(removedStyles);
        
            // This next code will only keep the last 10 previous items with styles
            // If you want to keep every single previous style, return adjustedStyles as set above
        
            const allRemoved = adjustedStyles.filter(item => item.removed === true);
            const withoutRemoved = adjustedStyles.filter(item => !item.removed);
        
            adjustedStyles = withoutRemoved.concat(allRemoved.slice(0,20));
        
            return adjustedStyles;
        },
        //
        // Gets the styles removed from oldStyles
        //
        getRemovedStyles: (oldStyles, adjustedStyles) => {
            // Get removed styles from the old styles
            let removedStyles = oldStyles.filter(oldItem => {
                return !adjustedStyles.find(newItem => newItem.classId === oldItem.classId) && oldItem.style;
            });

            removedStyles = removedStyles.map(item => {
                item.removed = true;
                return item;
            });
            return removedStyles;
        },
        //
        // Gets the removed shapes
        //
        getRemovedShapes: (oldShapes, adjustedShapes) => {
            let removedShapes = oldShapes.filter(oldItem => {
                return !adjustedShapes.find(newItem => newItem.classId === oldItem.classId && newItem.instance === oldItem.instance);
            });

            removedShapes = removedShapes.map(item => {
                item.removed = true;
                return item;
            });
            return removedShapes;
        },
        //
        // Gets the added shapes
        //
        getAddedShapes: (oldShapes, adjustedShapes) => {
            const addedShapes = adjustedShapes.filter(newItem => {
                return !oldShapes.find(oldItem => {
                    //console.log({oldItem, newItem}, 'oldInstance', oldItem.instance, 'newInstance', newItem.instance, 'oldClassId', oldItem.classId, 'newClassId', newItem.classId);
                   return oldItem.instance === newItem.instance && oldItem.classId === newItem.classId
                });
            });
            return addedShapes;
        },
        //
        // Creates formatted array with removed shapes
        //
        adjustRemovedShapes: (oldShapes, adjustedShapes) => {

            // Need to get the correct addedShapes. Getting by classId and instance is better but still doesn't work
            const addedShapes = methods.getAddedShapes(oldShapes, adjustedShapes);
            const removedShapes = methods.getRemovedShapes(oldShapes, adjustedShapes);

            if (addedShapes.length === 0) {
                for (let oldShape of oldShapes) {
                    const isInNewShapes = adjustedShapes.find(newShape => newShape.classId === oldShape.classId && newShape.instance === oldShape.instance);
                    oldShape.removed = !isInNewShapes;
                }
                console.log({oldShapes});
                console.log({removedShapes});
                return oldShapes;
            } else {
                console.log({adjustedShapes});
                return adjustedShapes;
            }
        },
        //
        // Makes initial formats to the lines
        //
        formatLines: (target) => {
            return target.map((item, index) => ({
                value: item,
                indents: numberOfTabs(item)
            }))
        },
        //
        // Retreives line data
        //
        lineData: (newShapes, lines) => {
            return lines.map((item, index) => {
                const classId = item.value.trim();
        
                let visible = true;
                let selectionBorderVisible = true;
                if (methods.oldItemAtIndexIsTheSame(newShapes, index, classId)) {
                    const itemData = newShapes[index];
                    visible = itemData.visible;
                    selectionBorderVisible = itemData.selectionBorderVisible;
                }
        
                return {
                    classId, 
                    indents: numberOfTabs(item.value), 
                    id: (newShapes[index]) ? newShapes[index].id : ID(),
                    parent:  null,
                    children: [],
                    visible,
                    selectionBorderVisible
                }
            })
        },
        //
        // Gets new code editor history array
        //
        // getNewCodeEditorHistory: (oldHistory, editorValue) => {
        //     const newHistory = clone(oldHistory);
        //     const splitEditorValue = editorValue.split('\n');
        //     const editorValueCleaned = splitEditorValue.map((item, valueIndex) => ({classId: item.trim(), index: valueIndex})).filter(item => item.classId && item.classId[item.classId.length - 1] !== '.');
        //     newHistory.unshift(editorValueCleaned);
        //     return newHistory.slice(0, 20);
        // },
        getNewCodeEditorHistory: (oldHistory, removedLines) => {
            const newHistory = clone(oldHistory);
            if (removedLines.length > 0) {
                return removedLines.concat(newHistory).slice(0, 20);
            } else {
                return newHistory;
            }
        },
        //
        // Get removed lines of code editor
        //
        getRemovedLines: (codeEditorHistory) => {
            const oldHistory = codeEditorHistory[1];
            const newHistory = codeEditorHistory[0];
            if (!oldHistory || !newHistory) return [];
            const oldSplitHistory = oldHistory.split('\n');
            const newSplitHistory = newHistory.split('\n');
            const valueWithoutWhitespace = oldSplitHistory.map((item, valueIndex) => ({classId: item.trim(), index: valueIndex})).filter(item => item.classId && item.classId[item.classId.length - 1] !== '.');
            const removedLines = [];
            oldSplitHistory.forEach((line, index) => {
                const cleanIndex = valueWithoutWhitespace.findIndex(item => item.index === index);
                if (!newSplitHistory.includes(line)) removedLines.push({line, index, cleanIndex});
            });
            return removedLines;
        },
        //
        // Get added lines of code editor
        //
        getAddedLines: (codeEditorHistory) => {
            const oldHistory = codeEditorHistory[1];
            const newHistory = codeEditorHistory[0];
            const oldSplitHistory = oldHistory.split('\n').map((line, index) => [line, index]);
            const newSplitHistory = newHistory.split('\n').map((line, index) => [line, index]);
            if (!oldHistory) return newSplitHistory;
            const valueWithoutWhitespace = newSplitHistory.map((item, valueIndex) => ({classId: item.trim(), index: valueIndex})).filter(item => item.classId && item.classId[item.classId.length - 1] !== '.');
            const addedLines = [];

            newSplitHistory.forEach((line, index) => {
                if (oldSplitHistory[index][0] !== line[0]) {
                    const newSlice = newSplitHistory.slice(index);

                }
            });

            // newSplitHistory.forEach((line, index) => {
            //     if (oldSplitHistory[index] !== line) {
            //         console.log({line}, {oldLine: oldSplitHistory[index]});
            //         const cleanIndex = valueWithoutWhitespace.findIndex(item => item.index === index);
            //         addedLines.push({line, index, cleanIndex});
            //         newSplitHistory.splice(index, 1);
            //     }
            // });
            return addedLines;
        },
        //
        // 
        //
        adjustShapesForAddedShapes: (addedShapes, removedShapes, finalShapes) => {
            // If new shape, see if it's in the removed shapes array
            // If it's in the removed shapes array, add it to finalShapes at the index
            // Then set the numInstances in finalShapes, and make sure the removedShapes are set to removed
            for (let addedShape of addedShapes) {
                const addedShapeIndex = removedShapes.findIndex(item => item.trimmedValue === addedShape.classId);
                if (addedShapeIndex > -1) {
                    const addedShapeIsInFinalShapes = finalShapes.find(item => item.id === addedShape.id && item.classId === addedShape.classId);
                    console.log({addedShapeInRemovedShapes: removedShapes[addedShapeIndex], addedShapeIsInFinalShapes});
                    if (addedShapeIsInFinalShapes) {
                        addedShapeIsInFinalShapes.removed = false;
                    } else {
                        addedShape.removed = true;
                        finalShapes.splice(removedShapes[addedShapeIndex].cleanIndex, 0, addedShape);
                    }
                }
            }
            console.log({addedShapes, removedShapes, finalShapes});
            return finalShapes;
        }
    }

    const getSavedSelection = (data) => {
        selectionRef.current = data;
    }

    const handleCodeEditorChange = (e) => {
        //console.log(shapesContext.lineSelected);
        // Set the code editor history
        //const newCodeEditorHistory = methods.getNewCodeEditorHistory(shapesContext.codeEditorHistory, shapesContext.lineSelected);
        shapesContext.setCodeEditorValue(e.target.value);
        //shapesContext.setCodeEditorHistory(newCodeEditorHistory);
        shapesContext.setShapes(oldShapes => {
            const newShapes = clone(oldShapes);

            const splitTarget = e.target.value.split('\n');

            const formattedLines = methods.formatLines(splitTarget);

            const lines = methods.lineData(newShapes, formattedLines);

            let finalShapes = [];

            const listOfClassIdsForLines = [];
            lines.forEach((item, index) => {
                const parent = findShapeParent(formattedLines, index);

                // Get children of each shape
                const children = findShapeDirectChildren(formattedLines, index);
                const childrenIds = children.map(item => {
                    if (lines[item]) return lines[item].id;
                });
                
                // Get parent of each shape
                item.parent = (parent && lines[parent.index]) ? lines[parent.index].id : null;
                item.children = childrenIds;
                // Filter out everything with a classId
                if (item.classId && item.classId[item.classId.length - 1] !== ".") {
                    listOfClassIdsForLines.push(item.classId);
                    const numInstances = listOfClassIdsForLines.filter(classId => classId === item.classId).length;
                    item.instance = numInstances;
                    finalShapes.push(item);
                }
            });

            // Find instance
            //finalShapes = methods.adjustRemovedShapes(oldShapes, finalShapes);
            
            //console.log(methods.getAddedShapes(oldShapes, finalShapes));
            //console.log({finalShapes});
            
            // If new shape, see if it's in the removed shapes array
            // If it's in the removed shapes array, add it to finalShapes at the index
            // Then set the numInstances in finalShapes, and make sure the removedShapes are set to removed
            
            //const addedShapes = methods.getAddedShapes(oldShapes, finalShapes);
            //finalShapes = methods.adjustShapesForAddedShapes(addedShapes, newCodeEditorHistory, finalShapes);
            //console.log({finalShapes});
            
            
            const listOfClassIds = [];
            finalShapes.forEach((shape, index) => {
                listOfClassIds.push(shape.classId);
                const numInstances = listOfClassIds.filter(item => item === shape.classId).length;
                finalShapes[index].instance = numInstances;
            });

            // Set Individual Styles state
            shapesContext.setIndividualStyles(oldIndividualStyles => {
                const newIndividualStyles = clone(oldIndividualStyles);
                let adjustedIndividualStyles = [];
                finalShapes.forEach((shape, index) => {
                    const adjustedStyle = {
                        style: '',
                        backgroundImage: '',
                        text: '',
                        classId: shape.classId + '._instance' + (shape.instance)
                    };
                    const styleInNewIndividualStyles = newIndividualStyles.find(item => item.classId === adjustedStyle.classId);
                    if (styleInNewIndividualStyles) {
                        adjustedStyle.style = styleInNewIndividualStyles.style;
                        adjustedStyle.backgroundImage = styleInNewIndividualStyles.backgroundImage;
                        adjustedStyle.text = styleInNewIndividualStyles.text;
                        adjustedStyle.isImage = styleInNewIndividualStyles.isImage || false;
                    }
                    adjustedIndividualStyles.push(adjustedStyle);
                });
                // Set Background Images state
                shapesContext.setIndividualBackgroundImages(oldBackgroundImages => {
                    return adjustBackgroundImages(oldBackgroundImages, adjustedIndividualStyles);
                });

                adjustedIndividualStyles = methods.adjustRemovedStyles(oldIndividualStyles, adjustedIndividualStyles);

                return adjustedIndividualStyles;
            });

            // Set General Styles state
            shapesContext.setStyles(oldStyles => {
                const newStyles = clone(oldStyles);

                let adjustedStyles = [];
                finalShapes.forEach((shape) => {
                    const allShapeClasses = shape.classId.split('.').filter(line => line && line[line.length - 1] !== ".").map(line => "." + line);
                    if (!allShapeClasses.includes(shape.classId) && shape.classId[shape.classId.length - 1] !== ".") {
                        allShapeClasses.push(shape.classId);
                    }
                    allShapeClasses.forEach((singularClass) => {
                        const style = {
                            classId: singularClass,
                            backgroundImage: '',
                            style: '',
                            text: '',
                            lineColor: getRandomColor()
                        }
                        const styleInNewStyles = newStyles.find(item => item.classId === style.classId);
                        if (styleInNewStyles) {
                            style.backgroundImage = styleInNewStyles.backgroundImage;
                            style.isImage = styleInNewStyles.isImage || false;
                            style.style = styleInNewStyles.style;
                            style.text = styleInNewStyles.text;
                            style.lineColor = styleInNewStyles.lineColor;
                            if (style.removed) {
                                delete style.removed;
                            }
                        }
                        adjustedStyles.push(style);
                    });
                });

                adjustedStyles = removeDuplicates(adjustedStyles);

                // Set regular background images
                shapesContext.setBackgroundImages(oldBackgroundImages => {
                    return adjustBackgroundImages(oldBackgroundImages, adjustedStyles);
                });

                adjustedStyles = methods.adjustRemovedStyles(oldStyles, adjustedStyles);

                return adjustedStyles;
                
            });
            return finalShapes;
        });
    }

    return (
        <div className="LayerPanel">
            <CodeEditor value={shapesContext.codeEditorValue} onChange={handleCodeEditorChange} savedSelection={getSavedSelection} />
            <StyleArea />
        </div>
    )
}

export default LayerPanel;