import Footer from './home-page/Footer';
import HomeNav from './home-page/HomeNav';
import LogInButton from './home-page/LogInButton';

const NotLoggedIn = () => {
    return (
        <div className="NotLoggedIn">
            <HomeNav />
            <div className="not-logged-in-container">
                <div className="not-logged-in-text">
                    <div className="logo-spinning">
                        <img className="logo-spinning-img" src='ultralogosymbol.svg'/>
                    </div>
                    <p>
                        Log in to access UltraCSS.
                    </p>
                </div>
                <LogInButton />
                <div className="privacy-not-logged-in">
                    By logging in with Google, you agree to our <a href="/privacy-policy">privacy policy</a>.
                </div>
            </div>
        </div>
    )
}

export default NotLoggedIn;