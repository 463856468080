import * as bsIconValues from 'react-icons/bs';
import * as mdIconValues from 'react-icons/md';
import customIcons from './_custom-icons';

const iconsBs = Object.entries(bsIconValues);
const iconsMd = Object.entries(mdIconValues);
const iconsCustom = Object.entries(customIcons);

const icons = new Map(iconsBs.concat(iconsMd).concat(iconsCustom));

export default icons;