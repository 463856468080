import { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import ActionSubmenu from '../ActionSubmenu';
import SubmenuInput from '../File/SubmenuInput';
import { MdOpenInBrowser, MdDelete } from 'react-icons/md';
import { BsPencilSquare } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import Loading from '../../Loading';
import ErrorMessage from '../../ErrorMessage';
import handleInputKeyDown from '../../../utils/_title-inputs';

const SavedProjects = () => {
    const firebase = useContext(FirebaseContext);

    const [openSubmenu, setOpenSubmenu] = useState({name: null, action: null});
    const [error, setError] = useState(null);

    const loadProject = async (id) => {
        firebase.setLoading(`load-project-${id}`);
        await firebase.methods.loadProjectIntoApp(id);
    }

    const handleDeleteProject = async (id) => {
        firebase.setLoading(`delete-project-${id}`);
        await firebase.methods.deleteProject(id);
        firebase.setLoading(null);
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const openActionSubmenu = (itemName, action) => {
        setOpenSubmenu({name: itemName, action});
    }

    const getResetComponentVisible = () => {
        setOpenSubmenu({name: null, action: null});
        setError(null);
    }

    const handleRenameProject = async (id, newTitle) => {
        setError(null);
        firebase.setLoading(`rename-project-${id}`);
        const errors = await firebase.methods.renameProject(id, newTitle);
        if (errors) {
            setError(errors);
        }
        firebase.setLoading(null);
    }

    return (
        <div className="SavedProjects overflowing-item">
            {firebase.listOfProjects.slice(0).reverse().map(item => (
                <div className="project-item" key={item.id}>
                    <div className="project-item-info-wrapper">
                        <div className="project-item-title">{item.title}</div>
                        {item.id !== firebase.documentId &&
                            <div className="project-item-right">
                                <button className="load-project" onClick={() => loadProject(item.id)} className="button-item" title="Load">
                                    {firebase.loading !== `load-project-${item.id}` &&
                                        <MdOpenInBrowser />
                                    }
                                    {firebase.loading === `load-project-${item.id}` &&
                                        <Loading />
                                    }
                                </button>
                                <button className="rename-project button-item" onClick={() => openActionSubmenu(item.id, "rename")} title="Rename">
                                    {firebase.loading !== `rename-project-${item.id}` &&
                                        <BsPencilSquare />
                                    }
                                    {firebase.loading === `rename-project-${item.id}` &&
                                        <Loading />
                                    }
                                </button>
                                <button className="button-item" onClick={() => openActionSubmenu(item.id, "delete")} title="Delete">
                                    {firebase.loading !== `delete-project-${item.id}` &&
                                        <MdDelete />
                                    }
                                    {firebase.loading === `delete-project-${item.id}` &&
                                        <Loading />
                                    }
                                </button>
                            </div>
                        }
                        {item.id === firebase.documentId &&
                            <div className="project-item-right">
                                <button className="load-project" style={{visibility: "hidden"}}>X</button>
                                <div className="project-open">Currently Open</div>
                            </div>
                        }
                    </div>
                    {openSubmenu.action === "delete" && openSubmenu.name === item.id &&
                        <ActionSubmenu 
                            resetComponentVisible={getResetComponentVisible} 
                            message={`Confirm Delete of ${item.title}`} 
                            onClick={() => handleDeleteProject(item.id)}
                            escapeMessage={true}
                        />
                    }
                    {openSubmenu.action === "rename" && openSubmenu.name === item.id &&
                        <ActionSubmenu 
                            resetComponentVisible={getResetComponentVisible}
                            escapeMessage={true}
                        >
                            <SubmenuInput onConfirm={(newTitle) => handleRenameProject(item.id, newTitle)} confirmLabel="Rename" inputLabel={`Rename ${item.title}`} className="submenu-rename-project" onKeyDown={handleInputKeyDown} maxLength={25}>
                            {error &&
                                <ErrorMessage message={error} />
                            }
                            </SubmenuInput>
                        </ActionSubmenu>
                    }
                </div>
            ))}
        </div>
    )
}

export default SavedProjects;