import { useContext, useRef } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import ReactHtmlParser from 'react-html-parser';
import icons from '../../utils/_icons';
import clone from '../../utils/_clone';

const iconRegex = /icon:[\n\s\t]*['"]?(.*?)['"]?;(?:$|\n)/;

const Shape = ({data}) => {
    const shapesContext = useContext(ShapesContext);
    const shapeElement = useRef(null);

    const getAllShapeClasses = () => {
        const classes = data.classId.split('.').filter(line => line);
        for (let [index, classId] of classes.entries()) {
            if (!isNaN(classId[0])) {
                classes[index] = 'num_class_' + classId;
            }
        }
        return classes.join(' ');
    }

    const getAllShapeClassesAsArray = () => {
        const classes = data.classId.split('.').filter(line => line).map(item => '.' + item);
        for (let [index, classId] of classes.entries()) {
            if (!isNaN(classId[0])) {
                classes[index] = '.num_class_' + classId;
            }
        }
        if (classes.indexOf(data.classId) === -1) {
            classes.push(data.classId);
        }
        const sorted = sortByNumberOfPeriods(classes);
        return sorted;
    }

    const sortByNumberOfPeriods = (array) => {
        const periodRegex = /\./g
        return array.sort((a, b) => {
            let aMatch = a.match(periodRegex);
            let bMatch = b.match(periodRegex);
            if (aMatch === null) {
                aMatch = {length: -1};
            }
            if (bMatch === null) {
                bMatch = {length: -1};
            }
            if (bMatch.length === aMatch.length) {
                return b.length - a.length;
            } else {
                return bMatch.length - aMatch.length; 
            }
        });
    }

    const itemIndividualStyleObjIndex = shapesContext.individualStyles.findIndex(item => item.classId === data.classId + '._instance' + data.instance);
    const itemIndividualStyleObj = shapesContext.individualStyles[itemIndividualStyleObjIndex];
    const itemIndividualBackgroundObj = shapesContext.individualBackgroundImages[itemIndividualStyleObjIndex];

    const getAllShapeClassesData = () => {
        const classes = getAllShapeClassesAsArray();
        let classObjects = [];
        classes.forEach((classItem) => {
            const classObjectIndex = shapesContext.styles.findIndex(item => item.classId === classItem);
            const originalClassObject = shapesContext.styles[classObjectIndex];
            const backgroundImageObject = shapesContext.backgroundImages[classObjectIndex];
            if (originalClassObject && backgroundImageObject) {
                const classObject = clone(originalClassObject);
                classObject.backgroundImage = backgroundImageObject.backgroundImage;
                classObject.backgroundImageName = backgroundImageObject.backgroundImageName;
                classObject.isImage = backgroundImageObject.isImage ? true : false;
                classObjects.push(classObject);
            }
        });
        return classObjects;
    }

    const htmlClassName = (rawClassId) => rawClassId.split('.').filter(item => item).join(' ')

    const stylesObj = getAllShapeClassesData();
    
    const itemHas = (type, individualObj, generalObj, checkForImage) => {
        const imageCheck = (obj) => {
            let result = null;
            if (checkForImage) {
               result = {};
               result[type] = obj[type];
               result.isImage = obj.isImage; 
               result.className = htmlClassName(obj.classId);
            } else {
                result = obj[type];
            }
            return result;
        }

        let itemHas = null;
        if (individualObj) {
            if (individualObj[type]) {
                itemHas = imageCheck(individualObj);
            } else {
                generalObj.some(obj => {
                    if (obj[type]) {
                        itemHas = imageCheck(obj);
                        return true;
                    }
                })
            }
        } else {
            generalObj.some(obj => {
                if (obj[type]) {
                    itemHas = obj[type];
                    return true;
                }
            });
        }
        return itemHas;
    }


    // For background images, favor the individual background image, and then the general class background image
    const itemHasBackgroundImage = itemHas('backgroundImage', itemIndividualBackgroundObj, stylesObj, true);
    const itemHasInnerText = itemHas('text', itemIndividualStyleObj, stylesObj);

    const matchClassToIcon = (styleObj, regex) => {
        let iconResult = null;
        const matchIcon = styleObj.style.match(regex);
        if (matchIcon) {
            const iconName = matchIcon[1];
            const classNameUsed = htmlClassName(styleObj.classId);
            iconResult = {iconName, classId: classNameUsed};
        }
        return iconResult;
    }

    const shapeHasIcon = (stylesObj, itemIndividualStyleObj, regex) => {
        let iconResult = null;
        const individualStyles = (itemIndividualStyleObj) ? itemIndividualStyleObj.style : null;
        if (individualStyles) {
            iconResult = matchClassToIcon(itemIndividualStyleObj, regex);
            if (!iconResult && stylesObj) {
                stylesObj.some(obj => {
                    iconResult = matchClassToIcon(obj, regex);
                    if (iconResult) return true;
                });
            }
        } else {
            if (stylesObj) {
                stylesObj.some(obj => {
                    iconResult = matchClassToIcon(obj, regex);
                    if (iconResult) return true;
                });
            }
        }
        if (!iconResult) {
            iconResult = {iconName: null, classId: null};
        }
        return iconResult;
    }

    const shapeIcon = shapeHasIcon(stylesObj, itemIndividualStyleObj, iconRegex);
    let iconInstance = null;
    if (shapeIcon) {
        const iconExists = icons.get(shapeIcon.iconName);
        if (iconExists) iconInstance = iconExists;
    }

    const lineSelected = shapesContext.lineSelected.map(line => {
        return shapesContext.shapes[line.cleanIndex]
    }).filter(shape => shape);
    const lineShape = lineSelected ? lineSelected.find(item => item.id === data.id) : null;

    let isActiveShape = false;
    if (lineShape) {
        if (lineShape.instance === data.instance && lineShape.classId === data.classId) {
            isActiveShape = true;
        }
    }

    const handleSelectShape = (e) => {
        const target = e.target.closest('.Shape');
        if (!target) return;
        if (target.dataset.id === data.id) {
            const codeEditorSplit = shapesContext.codeEditorValue.split('\n').map((item, index) => ({value: item, index})).filter(item => item.value.trim() === data.classId);
            const indexOfShape = shapesContext.shapes.findIndex(item => item.id === data.id);
            const clickedOnLine = codeEditorSplit[data.instance - 1];
            if (clickedOnLine) {
                if (isActiveShape) {
                    shapesContext.setLineSelected([]);
                } else {
                    shapesContext.setLineSelected([{index: clickedOnLine.index, value: clickedOnLine.value, cleanIndex: indexOfShape}]);
                    document.querySelector('.code-editor-textarea').scrollTop = (clickedOnLine.index * 20) - 20;
                }
            }
        }
    }

    return (
        <div className={`Shape ${getAllShapeClasses()} ${'_instance' + data.instance} ${(isActiveShape && data.selectionBorderVisible) ? 'active-shape' : ''}`}
            style={{backgroundImage: (itemHasBackgroundImage && !itemHasBackgroundImage.isImage) ? `url('${itemHasBackgroundImage.backgroundImage}')` : ''}}
            onClick={handleSelectShape}
            data-id={data.id}
            ref={shapeElement}
        >
            {itemHasBackgroundImage && itemHasBackgroundImage.isImage &&
                <img src={itemHasBackgroundImage.backgroundImage} className={itemHasBackgroundImage.className}></img>
            }
            {iconInstance && (!itemHasBackgroundImage || !itemHasBackgroundImage.isImage) &&
                iconInstance({className: shapeIcon.classId})
            }
            {itemHasInnerText && ReactHtmlParser(itemHasInnerText[1])}
            {data.children.map(child => {
                const childData = shapesContext.shapes.find(item => item.id === child);
                if (childData && !childData.removed) {
                    return (<Shape key={child} data={childData} />)
                }
            })}
        </div>
    )
}

export default Shape;