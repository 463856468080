import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeNav from './HomeNav';
import '../../stylesheets/HomePage.scss';
import '../../stylesheets/Navbar.scss';
import Footer from './Footer';
import { BsFolderSymlinkFill, BsChevronRight, BsSearch, BsFillAlarmFill, BsFillArchiveFill, BsFillAspectRatioFill, BsFillAwardFill } from 'react-icons/bs';
import { MdOpenInNew, MdDelete, MdFormatListBulleted, MdFolder, MdAddAPhoto, MdOpenInBrowser } from 'react-icons/md';
import WebFont from 'webfontloader';

WebFont.load({
    google: {
        families: ['Cookie', 'Lato', 'PT Serif', 'Roboto Slab', 'Ubuntu Mono', 'Indie Flower', 'Bangers']
    }
});

const HomePage = () => {

    useEffect(() => {
        if (document.body.style.overflow !== "visible") {
            document.body.style.overflow = "visible";
        }
        const meta = document.querySelector('meta[name="viewport"]');
        if (meta.getAttribute('content').includes('initial-scale=0.5')) {
            meta.setAttribute('content', 'width=device-width, initial-scale=1');
        }
    });

    const directoryItem = (name, src) => {
        return (
            <div className="directory-item">
                <div className="directory-item-list-content-wrapper">
                    <div className="directory-item-left-side">
                        <div className="directory-item-image">
                            <img className="uploaded-image" src={src} />
                        </div>
                    </div>
                    <div className="directory-item-right-side">
                        <div className="directory-item-name">{name}</div>
                        <div className="directory-item-actions">
                            <button className="directory-action"><BsFolderSymlinkFill /></button>
                            <button className="directory-action"><MdOpenInNew /></button>
                            <button className="directory-action"><MdDelete /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const fontPreviewListItem = (fontFamily) => {
        return (
            <div className="font-preview-list-item">
                <div className="font-preview" style={{fontFamily}}>font-family: {fontFamily};</div>
                <button><MdDelete /></button>
            </div>
        )
    }

    const projectItem = (name) => {
        return (
            <div className="project-item">
                <div className="project-item-info-wrapper">
                    <div className="project-item-title">{name}</div>
                    <div className="project-item-right">
                        <button className="load-project"><MdOpenInBrowser /></button>
                        <button><MdDelete /></button>
                    </div>
                </div>
            </div>
        )
    }

    const searchResult = (icon, iconComponent) => {
        return (
            <div className="result">
                <div className="result-icon">{iconComponent}</div>
                <div className="result-name">icon: {icon};</div>
            </div>
        )
    }

    return (
        <div className="HomePage">
            <HomeNav>
                <a href="#about" className="home-nav-link">About</a>
                <a href="#features" className="home-nav-link">Features</a>
                <a href="#pricing" className="home-nav-link">Pricing</a>
                <Link to="/app" className="go-to-app-link"><div className="go-to-app-button">Go to app</div></Link>
            </HomeNav>
            <div className="block-container">
                <div className="copy-wrapper">
                    <h1 className="cta">Design mockups and graphics with CSS, quick and easy.</h1>
                </div>
            </div>
            <div className="block-container video-block">
                <div className="copy-wrapper">
                    <div className="video-demo">
                        <video className="video-demo-el" width="100%" muted loop controls>
                            <source src="ultracssdemo.mp4" type="video/mp4"/>
                            Your browser does not support the Video element.
                        </video>
                    </div>
                </div>
            </div>
            <div id="about" className="block-container main-text-block">
                <div className="copy-wrapper">
                    <h2>An elegant way to design with CSS.</h2>
                    <div className="description">
                        UltraCSS is for those who know CSS but find designing with CSS to be cumbersome. You might find that HTML takes a long time to write, or that linking CSS classes with HTML elements slows the design process down.
                    </div>
                    <div className="description">
                        Being 100% sure your padding is 32px, or that your margin is 1.5rem allows you to free yourself from pixel paranoia. Design knowing the exact dimensions of your elements.
                    </div>
                    <div className="description">
                        Create social media post templates, resumes, posters, UI mockups, or whatever graphic you can think of with UltraCSS.
                    </div>
                </div>
            </div>
            <div id="features" className="block-container headline-block">
                <div className="copy-wrapper">
                    <h2>Features</h2>
                </div>
            </div>
            <div className="block-container features-block">
                <div className="copy-wrapper">
                    <div className="home-feature">
                        <div className="feature-description">Write HTML without writing HTML</div>
                        <div className="feature-example">
                            <div className="feature-example-left">
                                <div className="feature-example-label light-red">Without UltraCSS</div>
                                <div className="feature-example-code">
                                    <pre>{`<div class="container">`}</pre>
                                    <pre>&#9;{`<div class="nav">`}</pre>
                                    <pre>&#9;&#9;{`<div class="itemA primary"></div>`}</pre>
                                    <pre>&#9;&#9;{`<div class="itemB"></div>`}</pre>
                                    <pre>&#9;&#9;{`<div class="itemC"></div>`}</pre>
                                    <pre>&#9;{`</div>`}</pre>
                                    <pre>{`</div>`}</pre>
                                </div>

                            </div>
                            <div className="feature-example-right">
                                <div className="feature-example-label light-green">With UltraCSS</div>
                                <div className="feature-example-code">
                                    <pre>.container</pre>
                                    <pre>&#9;.nav</pre>
                                    <pre>&#9;&#9;.itemA.primary</pre>
                                    <pre>&#9;&#9;.itemB</pre>
                                    <pre>&#9;&#9;.itemC</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-feature">
                        <div className="feature-description">Create variables for reusable CSS</div>
                        <div className="feature-example">
                            <div className="feature-example-left">
                                <div className="feature-example-label light-red">Without UltraCSS</div>
                                <div className="feature-example-code">
                                    <pre>/* You can't define properties or</pre>
                                    <pre>entire chunks of CSS as variables,</pre>
                                    <pre>so only var(--blue) can be defined. */</pre>
                                    <pre></pre>
                                    <pre>:root {`{`}</pre>
                                    <pre>&#9;--blue: #4492C6;</pre>
                                    <pre>{`}`}</pre>
                                </div>
                            </div>
                            <div className="feature-example-right">
                                <div className="feature-example-label light-green">With UltraCSS</div>
                                <div className="feature-example-code">
                                    <pre>$blue = "#4492C6";</pre>
                                    <pre>$flexCenter = "</pre>
                                    <pre>&#9;display: flex;</pre>
                                    <pre>&#9;align-items: center;</pre>
                                    <pre>&#9;justify-content: center";</pre>
                                    <pre>$centerBg = "</pre>
                                    <pre>&#9;background-size: cover;</pre>
                                    <pre>&#9;background-position: center";</pre>
                                    <pre>$pt = "padding-top";</pre>
                                    <pre>$pb = "padding-bottom";</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-feature">
                        <div className="feature-description">Style elements with ease</div>
                        <div className="feature-example">
                            <div className="feature-example-left">
                                <div className="feature-example-label light-red">Without UltraCSS</div>
                                <div className="feature-example-code">
                                    <pre>display: flex;</pre>
                                    <pre>align-items: center;</pre>
                                    <pre>justify-content: center;</pre>
                                    <pre>color: var(--blue);</pre>
                                    <pre>background-image: url('https://example.com/x.jpg');</pre>
                                    <pre>background-size: cover;</pre>
                                    <pre>background-position: center;</pre>
                                    <pre>height: 1.5rem;</pre>
                                    <pre>padding-top: 0.75rem;</pre>
                                    <pre>padding-bottom: 0.5rem;</pre>
                                </div>

                            </div>
                            <div className="feature-example-right">
                                <div className="feature-example-label light-green">With UltraCSS</div>
                                <div className="feature-example-code">
                                    <pre>$flexCenter;</pre>
                                    <pre>background-image: cat;</pre>
                                    <pre>text: Check out my cute cat!;</pre>
                                    <pre>color: $blue;</pre>
                                    <pre>$centerBg;</pre>
                                    <pre>height: 1.5rem;</pre>
                                    <pre>$pt: 0.75rem;</pre>
                                    <pre>$pb: 0.5rem;</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-features-container">
                        <div className="home-feature">
                            <div className="feature-description">Upload images for easy use in your projects</div>
                            <div className="feature-example">
                                <div className="nav-menu-wrapper example-nav-menu-wrapper">
                                    <div className="Uploads">
                                        <div className="DirectoryContents">
                                            <div className="directory-info-wrapper">
                                                <div className="current-directory">
                                                    <span className="item-name-left-folder"><MdFolder /></span>
                                                    Petstagram
                                                </div>
                                                <div className="directory-info-right">
                                                    <div className="button-wrapper">
                                                        <button><MdFormatListBulleted /></button>
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <button><MdAddAPhoto /></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-of-directory-items overflowing-item">
                                                {directoryItem('dog', 'images/dog.jpg')}
                                                {directoryItem('cat', 'images/cat.jpg')}
                                                {directoryItem('dog2', 'images/dog2.jpg')}
                                                {directoryItem('cat2', 'images/cat2.jpg')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-feature">
                            <div className="feature-description">Import Google fonts</div>
                            <div className="feature-example">
                                <div className="nav-menu-wrapper example-nav-menu-wrapper">
                                    <div className="ImportFont">
                                        <div className="fonts-list">
                                            {fontPreviewListItem('Cookie')}
                                            {fontPreviewListItem('Lato')}
                                            {fontPreviewListItem('PT Serif')}
                                            {fontPreviewListItem('Roboto Slab')}
                                            {fontPreviewListItem('Ubuntu Mono')}
                                            {fontPreviewListItem('Indie Flower')}
                                            {fontPreviewListItem('Bangers')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="nav-features-container">
                        <div className="home-feature">
                            <div className="feature-description">Save projects to your account</div>
                            <div className="feature-example">
                                <div className="nav-menu-wrapper example-nav-menu-wrapper">
                                    <div className="SavedProjects overflowing-item">
                                        {projectItem('Petstagram App')}
                                        {projectItem('Resume')}
                                        {projectItem('June Festival Poster')}
                                        {projectItem('Startup Landing Page')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-feature">
                            <div className="feature-description">Over 1,600 Material Design and Bootstrap icons included</div>
                            <div className="feature-example">
                                <div className="nav-menu-wrapper example-nav-menu-wrapper">
                                    <div className="Icons">
                                        <div className="icon-search-box-container example-icon-search-box-container">
                                            <input type="text" className="search-box example-search-box"/>
                                            <div className="search-box-icon example-search-box-icon"><BsSearch /></div>
                                        </div>
                                        <div className="search-results example-search-results">
                                            {searchResult('BsFillAlarmFill', <BsFillAlarmFill />)}
                                            {searchResult('BsFillArchiveFill', <BsFillArchiveFill />)}
                                            {searchResult('BsFillAspectRatioFill', <BsFillAspectRatioFill />)}
                                            {searchResult('BsFillAwardFill', <BsFillAwardFill />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pricing" className="block-container headline-block">
                <div className="copy-wrapper">
                    <h2>Pricing</h2>
                </div>
            </div>
            <div className="block-container">
                <div className="copy-wrapper">
                    <div className="pricing"><strong>Free</strong> (for now). UltraCSS may become a paid service in the future.</div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage;