import { useEffect, useContext } from "react";
import { FirebaseContext } from '../context/FirebaseContext';

const CompletedMessage = ({message}) => {
    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        setTimeout(() => {
            firebase.setCompletedMessage(null);
        }, 3000);
    });

    return (
        <div className="CompletedMessage">
            {message}
        </div>
    )
}

export default CompletedMessage;