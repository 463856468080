import { useContext } from 'react';
import { FirebaseContext } from '../context/FirebaseContext';

const LogInButton = () => {
    const firebaseContext = useContext(FirebaseContext);
    const firebase = firebaseContext.app;

    const handleLogIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth()
            .signInWithPopup(provider)
            .catch((error) => {
                console.error('error', error);
            });
    }

    return (
        <div className="LogInButton" onClick={handleLogIn}>
            <img className="google-icon" src="btn_google_light_normal_ios.svg"/>
            <span>Sign in with Google</span>
        </div>
    )
}

export default LogInButton;