import { Link } from 'react-router-dom';
import HomeNav from './HomeNav';

const NotFound404 = () => {
    return (
        <div className="NotFound404">
            <HomeNav>
                <Link to="/app" className="go-to-app-link"><div className="go-to-app-button">Go to app</div></Link>
            </HomeNav>
            <div className="not-logged-in-container">
                <h1>404</h1>
                <h3>The page was not found.</h3>
            </div>
        </div>
    )
}

export default NotFound404;