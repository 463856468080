import { useContext, useRef } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import enableTab from '../../utils/_enable-tab-textarea';

const Variables = () => {
    const shapesContext = useContext(ShapesContext);
    const textareaRef = useRef(null);

    const handleChange = (e) => {
        shapesContext.setVariables(e.target.value);
    }

    const handleMouseDown = (e) => {
        textareaRef.current.focus();
    }

    const handleKeyDown = (e) => {
        if (e.key === "Tab") {
            e.preventDefault();
            enableTab(e);
        }
    }

    return (
        <div className="Variables">
            <textarea ref={textareaRef} className="variables-textarea" onKeyDown={handleKeyDown} onMouseDown={handleMouseDown} onChange={handleChange} value={shapesContext.variables}>{shapesContext.variables}</textarea>
        </div>
    )
}

export default Variables;