import { useContext, useState } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import { FirebaseContext } from '../context/FirebaseContext';
import clone from '../../utils/_clone';
import { sortClassesByLength } from '../../utils/_code-editor';
import enableTab from '../../utils/_enable-tab-textarea';
import { adjustBackgroundImages } from '../../utils/_styles';
import { BsFillInfoCircleFill, BsListUl } from 'react-icons/bs';
import { MdShuffle, MdContentCopy, MdReplyAll } from 'react-icons/md';
import { CheckSquareFill, XCircleFill } from '../../utils/_custom-icons';
import Tooltip from '../Tooltip';
import StylesList from './StylesList';
import { useHotkeys } from 'react-hotkeys-hook';

const textRegex = /text:[\n\s\t]*['"]?(.*?)['"]?;(?:$|\n)/s;
const backgroundImageRegex = /background-image:[\n\s\t]*['"]?(.*?)['"]?;(?:$|\n)/s;
const imageRegex = /img:[\n\s\t]*['"]?(.*?)['"]?;(?:$|\n)/;

const StyleArea = () => {
    const shapesContext = useContext(ShapesContext);
    const firebase = useContext(FirebaseContext);
    const lineSelected = shapesContext.lineSelected;
    const currentShapes = [];
    for (let line of lineSelected) {
        const shapeObj = clone(shapesContext.shapes[line.cleanIndex]);
        if (shapeObj) {
            shapeObj.cleanIndex = line.cleanIndex;
            currentShapes.push(shapeObj);
        }
    }

    const [openInfoArea, setOpenInfoArea] = useState(null);
    const [backgroundImageDirectoryName, setBackgroundImageDirectoryName] = useState(null);

    useHotkeys('ctrl+z', (e) => {
        e.preventDefault();
        shapesContext.undoReplaceStyles();
    }, [shapesContext]);

    useHotkeys('command+z', (e) => {
        e.preventDefault();
        shapesContext.undoReplaceStyles();
    }, [shapesContext]);

    const handleBackgroundMatch = async (newStyles, classId, backgroundImageMatches, isImage) => {
        const currentStyle = newStyles.find(item => item.classId === classId);
        const imageIsLoaded = firebase.imagesLoaded.find(item => item.backgroundImageName === backgroundImageMatches[1]);
        try {
            let url = null;
            if (imageIsLoaded) {
                url = imageIsLoaded.backgroundImage;
            } else {
                url = await firebase.methods.singleImageRef(backgroundImageMatches[1]).getDownloadURL();
                firebase.methods.addImageLoaded(url, backgroundImageMatches[1]);
            }
            currentStyle.backgroundImage = url;
            currentStyle.backgroundImageName = backgroundImageMatches[1];
            currentStyle.isImage = isImage ? true : false;
            return newStyles;
        } catch (err) {
            currentStyle.backgroundImage = '';
            currentStyle.backgroundImageName = backgroundImageMatches[1];
            currentStyle.isImage = isImage ? true : false;
            return newStyles;
        }
    }

    const setStyleType = (localStorageLabel, oldStyles, classId, e) => {
        const newStyles = clone(oldStyles);
        const currentStyleIndex = newStyles.findIndex(item => item.classId === classId);
        const currentStyle = newStyles[currentStyleIndex];
        currentStyle.style = e.target.value;

        currentStyle.text = currentStyle.style.match(textRegex) || '';

        const imageMatches = currentStyle.style.match(imageRegex);
        const backgroundImageMatches = currentStyle.style.match(backgroundImageRegex);

        // Select correct background image state type
        const backgroundImageType = (localStorageLabel === "individual-styles") ? shapesContext.individualBackgroundImages : shapesContext.backgroundImages;
        const selectedSetBackgroundImages = (localStorageLabel === "individual-styles") ? shapesContext.setIndividualBackgroundImages : shapesContext.setBackgroundImages;
        const shapeBackgroundImage = backgroundImageType[currentStyleIndex];

        const handleMatchLogic = (matches, isImage) => {
            if (matches[1].includes('http://') || matches[1].includes('https://')) {
                // For standard URLs
                if (shapeBackgroundImage && shapeBackgroundImage.backgroundImage !== matches[1]) {
                    // Only set when the background image match is not the same as what the background currently is
                    selectedSetBackgroundImages(oldBackgroundImages => {
                        const newBackgroundImages = clone(oldBackgroundImages);
                        const currentShapeBackground = newBackgroundImages[currentStyleIndex];
                        currentShapeBackground.backgroundImage = matches[1];
                        currentShapeBackground.isImage = isImage ? true : false;
                        return newBackgroundImages;
                    });
                }
            } else {
                if (firebase.currentUser && (shapeBackgroundImage.backgroundImageName !== matches[1] || shapeBackgroundImage.isImage !== isImage)) {
                    // Triggered new handle background match
                    const newBackgroundImages = adjustBackgroundImages(backgroundImageType, newStyles);
                    handleBackgroundMatch(newBackgroundImages, classId, matches, isImage).then(adjustedNewStyles => {
                        selectedSetBackgroundImages(adjustedNewStyles);
                    });
                }
            }
        }

        const nonMatchHasBGLogic = (isImage) => {
            selectedSetBackgroundImages(oldBackgroundImages => {
                const newBackgroundImages = clone(oldBackgroundImages);
                const currentTargetingBackgroundImage = newBackgroundImages[currentStyleIndex];
                currentTargetingBackgroundImage.backgroundImage = '';
                currentTargetingBackgroundImage.backgroundImageName = '';
                currentTargetingBackgroundImage.isImage = isImage ? true : false;
                return newBackgroundImages;
            });
        }

        if (imageMatches) {
            handleMatchLogic(imageMatches, true);
        } else if (backgroundImageMatches) {
            handleMatchLogic(backgroundImageMatches, false);
        } else if (shapeBackgroundImage && shapeBackgroundImage.backgroundImage) {
            if (shapeBackgroundImage.isImage) {
                nonMatchHasBGLogic(true);
            } else {
                nonMatchHasBGLogic(false);
            }
        }

        return newStyles;
    }
    
    const handleChangeClassStyle = async (e, classId) => {
        shapesContext.setStyles(oldStyles => {
            return setStyleType('styles', oldStyles, classId, e);
        });
    }

    const handleChangeIndividualStyle = (e, classId) => {
        shapesContext.setIndividualStyles(oldStyles => {
            return setStyleType('individual-styles', oldStyles, classId, e);
        });
    }

    const currentShapeClasses = (currentShape) => {
        let classes = currentShape.split('.').filter(line => line && line[line.length - 1] !== ".").map(line => "." + line);
        classes = sortClassesByLength(classes);
        classes = Array.from(new Set(classes));
        return classes;
    }

    const getLineInstance = (currentShape) => {
        if (currentShape) {

            const instances = [];
            shapesContext.shapes.forEach((shape, index) => {
                if (shape.classId === currentShape.classId) {
                    instances.push(index);
                }
            });
            const indexOfCurrentShape = instances.indexOf(currentShape.cleanIndex);
            const individualStyle = shapesContext.individualStyles.find(item => item.classId === `${currentShape.classId}._instance${indexOfCurrentShape + 1}`);
            return individualStyle;
        }
        return null;
    }

    const handleTextAreaKeyDown = (e, classId) => {
        if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            firebase.methods.handleSaveProject(firebase.documentId);
            return;
        }
        if (e.key === "Tab") {
            enableTab(e);
            handleChangeClassStyle(e, classId);
        }
    }

    const handleKeyDownIndividualStyle = (e, classId) => {
        if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            firebase.methods.handleSaveProject(firebase.documentId);
            return;
        }
        if (e.key === "Tab") {
            enableTab(e);
            handleChangeIndividualStyle(e, classId);
        }
    }

    const handleHideSelectionBorder = (currentShapeItem) => {
        shapesContext.setShapes(oldShapes => {
            const newShapes = clone(oldShapes);
            const currentShape = newShapes[currentShapeItem.cleanIndex];
            currentShape.selectionBorderVisible = (currentShape.selectionBorderVisible) ? false : true;
            return newShapes;
        });
    }

    const handleDeselectShape = () => {
        shapesContext.setLineSelected([]);
    }
    
    const handleOpenStyleInfo = async (styles, classId) => {
        setOpenInfoArea(classId);
        setBackgroundImageDirectoryName('Loading...');
        // Get background image matches
        let matches = null;
        const backgroundImageMatches = styles.match(backgroundImageRegex);
        const imageMatches = styles.match(imageRegex);
        matches = imageMatches || backgroundImageMatches;
        if (matches) {
            const uploadName = matches[1];
            const directoryId = await firebase.methods.getDirectoryOfImage(uploadName);
            if (directoryId && directoryId.customMetadata && directoryId.customMetadata.documentId) {
                try {
                    const projectData = await firebase.methods.getSingleProjectData(directoryId.customMetadata.documentId);
                    const projectTitle = projectData.title || null;
                    setBackgroundImageDirectoryName(projectTitle);
                } catch (err) {
                    console.error(err);
                    setBackgroundImageDirectoryName(null);
                }
            } else {
                setBackgroundImageDirectoryName('Not Found');
            }
        } else {
            setBackgroundImageDirectoryName(null);
        }
    }

    const handleCloseStyleInfo = () => {
        setOpenInfoArea(null);
        setBackgroundImageDirectoryName(null);
    }

    const handleActivateReplaceStyles = (style, classId, action, isStylesList = false) => {
        if (!isStylesList) {
            shapesContext.setCurrentlyReplacingStyles(classId);
        } else {
            shapesContext.setUseDifferentStyles(classId);
        }
        let styleObj = null;
        let backgroundObj = null;
        if (classId.includes('._instance')) {
            styleObj = shapesContext.individualStyles.find(item => item.classId === classId);
            backgroundObj = shapesContext.individualBackgroundImages.find(item => item.classId === classId);
        } else {
            styleObj = shapesContext.styles.find(item => item.classId === classId);
            backgroundObj = shapesContext.backgroundImages.find(item => item.classId === classId);
        }
        styleObj.action = action;
        if (styleObj) {
            shapesContext.setReplaceStyles(oldReplaceStyles => {
                const newReplaceStyles = clone(oldReplaceStyles);
                newReplaceStyles.unshift([{styleObj, backgroundObj}, {styleObj: null, backgroundObj: null}]);
                return newReplaceStyles.slice(0, 20);
            });
        }
    }

    const handleReplaceStyles = (classIdTarget) => {
        const replaceStyles = shapesContext.replaceStyles[0];
        const replaceStylesInitiator = replaceStyles[0];
        const action = replaceStylesInitiator.styleObj.action;

        const hasBackground = (classId) => shapesContext.isInstance(classId) ? shapesContext.individualBackgroundImages.find(item => item.classId === classId) : shapesContext.backgroundImages.find(item => item.classId === classId);
        const hasStyles = (classId) => shapesContext.isInstance(classId) ? shapesContext.individualStyles.find(item => item.classId === classId) : shapesContext.styles.find(item => item.classId === classId);

        const backgroundImagesState = (oldImages, targetClass, backgroundObj) => {
            const newImages = clone(oldImages);
            const targetObj = newImages.find(item => item.classId === targetClass);
            if (targetObj) {
                targetObj.backgroundImage = backgroundObj.backgroundImage;
                targetObj.backgroundImageName = backgroundObj.backgroundImageName;
                targetObj.isImage = backgroundObj.isImage;
            }
            return newImages;
        }

        // Function which copies the styles of the initiator to the target
        const replaceStylesState = (oldStyles, targetClassId, initiator) => {
            const newStyles = clone(oldStyles);
            const targetedStyle = newStyles.find(item => item.classId === targetClassId);
            if (targetedStyle) {
                targetedStyle.backgroundImage = initiator.styleObj.backgroundImage;
                targetedStyle.isImage = initiator.styleObj.isImage;
                targetedStyle.style = initiator.styleObj.style;
                targetedStyle.text = initiator.styleObj.text;
            }
            return newStyles;
        }
        
        // Determine if target is an instance
        const targetIsInstance = shapesContext.isInstance(classIdTarget);
        // Determine if initiator is an instance
        const initiatorIsInstance = shapesContext.isInstance(replaceStylesInitiator.styleObj.classId);
        
        // Get background objects for both target and initiator
        const initiatorBackgroundObj = hasBackground(replaceStylesInitiator.styleObj.classId);
        const targetBackgroundObj = hasBackground(classIdTarget);

        // Get target style object for swap
        const targetStyleObj = hasStyles(classIdTarget);
        
        // Determine which background images state to set for the target
        const setTargetBackgroundState = targetIsInstance ? shapesContext.setIndividualBackgroundImages : shapesContext.setBackgroundImages;
        // Determine which backgorund images state to set for the initiator
        const setInitiatorBackgroundState = initiatorIsInstance ? shapesContext.setIndividualBackgroundImages : shapesContext.setBackgroundImages;

        const classIdStyleObj = targetIsInstance ? shapesContext.individualStyles.find(item => item.classId === classIdTarget) : shapesContext.styles.find(item => item.classId === classIdTarget);
       

        // Determines if the individual styles or regular styles state should be set 
        // For target and initiator
        const setTargetStyleState = targetIsInstance ? shapesContext.setIndividualStyles : shapesContext.setStyles;
        const setInitiatorStyleState = initiatorIsInstance ? shapesContext.setIndividualStyles : shapesContext.setStyles;

        // Determines if individual or regular styles get set in replaceStylesState
        // For target and initiator
        const oldTargetStyles = targetIsInstance ? shapesContext.individualStyles : shapesContext.styles;
        const oldBackgroundImages = targetIsInstance ? shapesContext.individualBackgroundImages : shapesContext.backgroundImages;

        // Get the updated array of objects for shapesContext.individualStyles/shapesContext.styles
        const newTargetStyleInitiator = replaceStylesState(oldTargetStyles, classIdTarget, replaceStylesInitiator);
        const newTargetBackgroundInitiator = backgroundImagesState(oldBackgroundImages, classIdTarget, initiatorBackgroundObj);

        if (action === 'Copy') {
            setTargetStyleState(newTargetStyleInitiator);
            // Copy background images
            if (initiatorBackgroundObj) {
                setTargetBackgroundState(newTargetBackgroundInitiator);
            }
        } else if (action === 'StylesList') {
            // Opposite of copy
            setInitiatorStyleState(oldStyles => {
                const newStyles = clone(oldStyles);
                const initiatorObj = newStyles.find(item => item.classId === replaceStylesInitiator.styleObj.classId);
                if (!initiatorObj || !targetStyleObj) return newStyles;
                initiatorObj.backgroundImage = targetStyleObj.backgroundImage;
                initiatorObj.style = targetStyleObj.style;
                initiatorObj.text = targetStyleObj.text;
                initiatorObj.isImage = targetStyleObj.isImage;
                delete initiatorObj.action;
                return newStyles;
            });
            setInitiatorBackgroundState(oldBackgroundImages => {
                const newBackgroundImages = clone(oldBackgroundImages);
                const initiatorObj = newBackgroundImages.find(item => item.classId === replaceStylesInitiator.backgroundObj.classId);
                if (!initiatorObj) return newBackgroundImages;
                initiatorObj.backgroundImage = initiatorObj.backgroundImageName = '';
                initiatorObj.isImage = false;
                return newBackgroundImages;
            });
        } else if (action === 'Move') {

            // Take the newTargetStyleInitiator and run it through a new function which deletes the style data for the initiator
            // Do the same for the backgroundImages
            // So you only have to set the state once and the state doesn't get overridden if the state is getting set twice

            // Both instances are the same type, so set the states in one function rather than separately
            if (initiatorIsInstance === targetIsInstance) {
                setInitiatorStyleState(oldStyles => {
                    const initiatorObj = newTargetStyleInitiator.find(item => item.classId === replaceStylesInitiator.styleObj.classId);
                    if (!initiatorObj) return newTargetStyleInitiator;
                    initiatorObj.backgroundImage = initiatorObj.style = initiatorObj.text = '';
                    initiatorObj.isImage = false;
                    delete initiatorObj.action;
                    return newTargetStyleInitiator;
                });
                setInitiatorBackgroundState(oldBackgroundImages => {
                    const initiatorObj = newTargetBackgroundInitiator.find(item => item.classId === replaceStylesInitiator.backgroundObj.classId);
                    if (!initiatorObj) return newTargetBackgroundInitiator;
                    initiatorObj.backgroundImage = initiatorObj.backgroundImageName = '';
                    initiatorObj.isImage = false;
                    return newTargetBackgroundInitiator;
                });
            } else {
                // Both instances are different types (either instance or not), so set the state separately
                setTargetStyleState(newTargetStyleInitiator);
                // Delete styles from initiator
                setInitiatorStyleState(oldStyles => {
                    const newStyles = clone(oldStyles);
                    const initiatorObj = newStyles.find(item => item.classId === replaceStylesInitiator.styleObj.classId);
                    if (!initiatorObj) return newStyles;
                    initiatorObj.backgroundImage = initiatorObj.style = initiatorObj.text = '';
                    initiatorObj.isImage = false;
                    delete initiatorObj.action;
                    return newStyles;
                });
                if (initiatorBackgroundObj) {
                    setTargetBackgroundState(newTargetBackgroundInitiator);
                }
                // Delete background images from initiator
                setInitiatorBackgroundState(oldBackgroundImages => {
                    const newBackgroundImages = clone(oldBackgroundImages);
                    const initiatorObj = newBackgroundImages.find(item => item.classId === replaceStylesInitiator.backgroundObj.classId);
                    if (!initiatorObj) return newBackgroundImages;
                    initiatorObj.backgroundImage = initiatorObj.backgroundImageName = '';
                    initiatorObj.isImage = false;
                    return newBackgroundImages;
                });  
            }
        } else if (action === "Swap") {
            if (initiatorIsInstance === targetIsInstance) {
                setInitiatorStyleState(oldStyles => {
                    const initiatorObj = newTargetStyleInitiator.find(item => item.classId === replaceStylesInitiator.styleObj.classId);
                    if (!initiatorObj || !targetStyleObj) return newTargetStyleInitiator;
                    initiatorObj.backgroundImage = targetStyleObj.backgroundImage;
                    initiatorObj.style = targetStyleObj.style;
                    initiatorObj.text = targetStyleObj.text;
                    initiatorObj.isImage = targetStyleObj.isImage;
                    delete initiatorObj.action;
                    return newTargetStyleInitiator;
                });
                setInitiatorBackgroundState(oldBackgroundImages => {
                    const initiatorObj = newTargetBackgroundInitiator.find(item => item.classId === replaceStylesInitiator.backgroundObj.classId);
                    if (!initiatorObj || !targetBackgroundObj) return newTargetBackgroundInitiator;
                    initiatorObj.backgroundImage = targetBackgroundObj.backgroundImage;
                    initiatorObj.backgroundImageName = targetBackgroundObj.backgroundImageName;
                    initiatorObj.isImage = targetBackgroundObj.isImage;
                    return newTargetBackgroundInitiator;
                });
            } else {
                // Both instances are different types (either instance or not), so set the state separately
                setTargetStyleState(newTargetStyleInitiator);
                // Swap styles with initiator
                setInitiatorStyleState(oldStyles => {
                    const newStyles = clone(oldStyles);
                    const initiatorObj = newStyles.find(item => item.classId === replaceStylesInitiator.styleObj.classId);
                    if (!initiatorObj || !targetStyleObj) return newStyles;
                    initiatorObj.backgroundImage = targetStyleObj.backgroundImage;
                    initiatorObj.style = targetStyleObj.style;
                    initiatorObj.text = targetStyleObj.text;
                    initiatorObj.isImage = targetStyleObj.isImage;
                    delete initiatorObj.action;
                    return newStyles;
                });
                if (initiatorBackgroundObj) {
                    setTargetBackgroundState(newTargetBackgroundInitiator);
                }
                // Swap background images
                setInitiatorBackgroundState(oldBackgroundImages => {
                    const newBackgroundImages = clone(oldBackgroundImages);
                    const initiatorObj = newBackgroundImages.find(item => item.classId === replaceStylesInitiator.backgroundObj.classId);
                    if (!initiatorObj || !targetBackgroundObj) return newBackgroundImages;
                    initiatorObj.backgroundImage = targetBackgroundObj.backgroundImage;
                    initiatorObj.backgroundImageName = targetBackgroundObj.backgroundImageName;
                    initiatorObj.isImage = targetBackgroundObj.isImage;
                    return newBackgroundImages;
                });  
            }
        }

        shapesContext.setReplaceStyles(oldReplaceStyles => {
            const newReplaceStyles = clone(oldReplaceStyles);
            const firstItem = newReplaceStyles[0];
            firstItem[1].styleObj = classIdStyleObj;
            firstItem[1].backgroundObj = targetBackgroundObj;
            return newReplaceStyles;
        });
        shapesContext.setCurrentlyReplacingStyles(null);
        shapesContext.setUseDifferentStyles(null);
    }

    const classLabel = (style, classId) => {
        return (
            <div className="label-container">
                <div className="class-label">{classId.replace('_instance', '')}</div>
                <div className="style-textarea-right-side">
                    {!shapesContext.currentlyReplacingStyles &&
                        <>
                            <Tooltip text="Copy styles" openFrom="left" className="replace-styles">
                                <div className="replace-styles-button" onClick={() => handleActivateReplaceStyles(style, classId, 'Copy')}>
                                    <MdContentCopy />
                                </div>
                            </Tooltip>
                            <Tooltip text="Swap styles" openFrom="left" className="replace-styles">
                                <div className="replace-styles-button" onClick={() => handleActivateReplaceStyles(style, classId, 'Swap')}>
                                    <MdShuffle />
                                </div>
                            </Tooltip>
                            <Tooltip text="Move styles" openFrom="left" className="replace-styles">
                                <div className="replace-styles-button" onClick={() => handleActivateReplaceStyles(style, classId, 'Move')}>
                                    <MdReplyAll />
                                </div>
                            </Tooltip>
                        </>
                    }
                    {!shapesContext.currentlyReplacingStyles && !shapesContext.useDifferentStyles &&
                        <Tooltip text="Import styles" openFrom="left" className="replace-styles">
                            <div className="tooltip-action" onClick={() => handleActivateReplaceStyles(style, classId, 'StylesList', true)}>
                                <BsListUl />
                            </div>
                        </Tooltip>
                    }
                    {shapesContext.useDifferentStyles === classId &&
                        <StylesList onConfirm={(targetClassId) => handleReplaceStyles(targetClassId)} onCancel={shapesContext.handleCancelReplaceStyles} />
                    }
                    {shapesContext.currentlyReplacingStyles === classId && !shapesContext.useDifferentStyles &&
                        <div className="replace-style-info ">Select a class...</div>
                    }
                    {shapesContext.currentlyReplacingStyles && shapesContext.currentlyReplacingStyles !== classId && !shapesContext.useDifferentStyles &&
                        <Tooltip text={`${shapesContext.replaceStyles[0][0].styleObj.action} here`} openFrom="left" className="replace-styles fill-green">
                            <div className="tooltip-action" onClick={() => handleReplaceStyles(classId)}>
                                <CheckSquareFill />
                            </div>
                        </Tooltip>
                    }
                    {(shapesContext.currentlyReplacingStyles || shapesContext.useDifferentStyles) &&
                        <Tooltip text="Cancel" openFrom="left" className="replace-styles">
                            <div className="tooltip-action" onClick={shapesContext.handleCancelReplaceStyles}>
                                <XCircleFill />
                            </div>
                        </Tooltip>
                    }
                    {(style.includes('background-image:') || style.includes('img:')) &&
                        <div className="style-textarea-action-info">
                            {classId === openInfoArea &&
                                <Tooltip text="Close style info" openFrom="left">
                                    <div className="info-container-is-open" onClick={handleCloseStyleInfo}>
                                        <XCircleFill />
                                    </div>
                                </Tooltip>
                            }
                            {classId !== openInfoArea &&
                                <Tooltip text="Style info" openFrom="left">
                                    <div className="info-container-is-closed" onClick={() => handleOpenStyleInfo(style, classId)}>
                                        <BsFillInfoCircleFill />
                                    </div>
                                </Tooltip>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="StyleArea">
            {currentShapes.map(currentShape => {
                const individualStyle = getLineInstance(currentShape);
                return (<div key={currentShape.id} className="current-shape-style-wrapper">
                    <div className="style-area-shape-buttons">
                        <button className="button-alt" onClick={() => handleHideSelectionBorder(currentShape)}>{(currentShape.selectionBorderVisible) ? 'Hide' : 'Show'} Selection Border</button>
                        <button className="button-alt" onClick={handleDeselectShape}>Deselect Shape</button>
                    </div>
                    {individualStyle && 
                        <div className="individual-style style-textarea-container">
                            {classLabel(individualStyle.style, individualStyle.classId)}
                            {individualStyle.classId === openInfoArea &&
                                <div className="style-textarea-info-container">
                                    {backgroundImageDirectoryName &&
                                        <div className="background-directory-name">Background Image Directory: {backgroundImageDirectoryName}</div>
                                    }
                                </div>
                            }
                            <textarea 
                                rows={individualStyle.style.split('\n').length}
                                className="style-textarea" 
                                value={individualStyle.style} 
                                onChange={(e) => handleChangeIndividualStyle(e, individualStyle.classId)}
                                onKeyDown={(e) => handleKeyDownIndividualStyle(e, individualStyle.classId)}
                            ></textarea>
                        </div>
                    }
                    {currentShapeClasses(currentShape.classId).map(classId => {
                        const styleData = shapesContext.styles.find(item => item.classId === classId);
                        if (styleData) {
                            return (
                                <div className="style-textarea-container" key={classId}>
                                    {classLabel(styleData.style, classId)}
                                    {classId === openInfoArea &&
                                        <div className="style-textarea-info-container">
                                            {backgroundImageDirectoryName &&
                                                <div className="background-directory-name">Background Image Directory: {backgroundImageDirectoryName}</div>
                                            }
                                        </div>
                                    }
                                    <textarea 
                                        rows={styleData.style.split('\n').length} 
                                        className="style-textarea" 
                                        value={styleData.style} 
                                        onChange={(e) => handleChangeClassStyle(e, classId)}
                                        onKeyDown={(e) => handleTextAreaKeyDown(e, classId)}
                                    ></textarea>
                                </div>
                            )
                        }
                    })}
                </div>)
            })}
        </div>
    )
}

export default StyleArea;