import { useState, createContext } from 'react';

const NavContext = createContext(null);
export { NavContext }

const NavProvider = ({children}) => {
    const [openNavMenu, setOpenNavMenu] = useState(false);

    return (
        <NavContext.Provider
            value={{
                openNavMenu, setOpenNavMenu
            }}
        >
            {children}
        </NavContext.Provider>

    )
}

export default NavProvider;