import ReactHtmlParser from 'react-html-parser';

const AreYouSure = ({message, onConfirm, onCancel, escapeMessage}) => {

    return (
        <div className="are-you-sure">
            <div className="are-you-sure-message">
                {escapeMessage ? message : ReactHtmlParser(message)}
            </div>
            <div className="are-you-sure-buttons">
                {typeof onCancel === 'function' &&
                    <button className="cancel-button" onClick={onCancel}>Cancel</button>
                }
                <button className="filled-button" onClick={onConfirm}>OK</button>
            </div>
        </div>
    )
}

export default AreYouSure;