import { useState } from 'react'; 

const Tooltip = ({openFrom, text, children, className, followMouse}) => {

    const openFromClass = openFrom || 'top';
    const [hovering, setHovering] = useState(false);

    const handleMouseOver = (e) => {
        if (!followMouse) return;
        const toolTipEl = e.target.closest('.Tooltip');
        const toolTipBox = toolTipEl.getBoundingClientRect();
        setHovering(toolTipBox);

    }

    const handleMouseOut = (e) => {
        if (!followMouse) return;
        setHovering(false);
    }

    const style = {
        display: hovering ? 'flex' : 'none',
        left: hovering ? hovering.left  + 'px' : 'unset',
        top: hovering ? hovering.top + (hovering.height / 2) + 'px' : 'unset'
    };

    return (
        <div className={`Tooltip ${className || ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <div className={`tooltip-message-wrapper ${openFromClass}-tooltip-wrapper ${followMouse ? 'fixed-tooltip' : ''}`} 
            style={followMouse ? style : {}}
            >
                <div className={`tooltip-message ${openFromClass}-tooltip`} >
                    {text}
                </div>
            </div>
            <div className="tooltip-children">
                {children}
            </div>
        </div>
    )

}

export default Tooltip;