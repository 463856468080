import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import FirebaseApp from './components/context/FirebaseContext';
import ShapesProvider from './components/context/ShapesContext';
import LayerPanel from './components/layer-panel/LayerPanel';
import ScopedStylesheet from './components/layer-panel/ScopedStylesheet';
import Navbar from './components/navbar/Navbar';
import ShapeArea from './components/shape-area/ShapeArea';
import Resize from './components/layer-panel/Resize';
import HomePage from './components/home-page/HomePage';
import './stylesheets/App.scss';
import PrivacyPolicy from './components/home-page/PrivacyPolicy';
import NotFound404 from './components/home-page/NotFound404';

if (window.localStorage.getItem('images-loaded')) {
  window.localStorage.removeItem('images-loaded');
}

const offset = window.innerWidth >= 768 ? 450 : 300;

function App() {
  const [rightOffset, setRightOffset] = useState(offset);

  const getWidthOfLayerPanel = (width) => {
    setRightOffset(width);
  }

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/app" exact>
            <ShapesProvider>
              <FirebaseApp>
                <ScopedStylesheet />
                <Navbar />
                <div className="main-app-area">
                  <ShapeArea rightOffset={rightOffset} />
                  <Resize initialWidth={offset} callback={getWidthOfLayerPanel}>
                    <LayerPanel />
                  </Resize>
                </div>
              </FirebaseApp>
            </ShapesProvider>
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="*">
            <NotFound404 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}


export default App;
