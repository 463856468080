export default function clone(x) {
	if(x == null || typeof x !== 'object') {
		return x;
	}

	if(Array.isArray(x)) {
		return cloneArray(x);
	}

	return cloneObject(x);
}

function cloneArray (x) {
	var l = x.length;
	var y = new Array(l);
	for (var i = 0; i < l; ++i) {
		y[i] = clone(x[i]);
	}
	return y;
}

function cloneObject (x) {
	var keys = Object.keys(x);
	var y = {};
	for (var k, i = 0, l = keys.length; i < l; ++i) {
		k = keys[i];
		y[k] = clone(x[k]);
	}

	return y;
}