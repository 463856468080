import clone from './_clone';

export const setDimensionsOnClass = (styleSheetSelector, classId, width, height) => {
    const styleSheet = styleSheetSelector.sheet;
    let classIdRule;
    for (let rule of styleSheet.cssRules) {
        if (rule.selectorText === ".ShapeArea " + classId) {
            rule.style.setProperty('width', width + "px");
            rule.style.setProperty('height', height + "px");
        }
    }
}

export const adjustBackgroundImages = (oldBackgroundImages, adjustedStyles) => {
    const newBackgroundImages = clone(oldBackgroundImages);
    const adjustedBackgroundImages = adjustedStyles.map(style => {
        return {classId: style.classId, backgroundImage: '', backgroundImageName: ''}
    });
    adjustedBackgroundImages.forEach(adjustedImg => {
        const imgInNewBackgroundImages = newBackgroundImages.find(item => item.classId === adjustedImg.classId);
        if (imgInNewBackgroundImages) {
            adjustedImg.backgroundImage = imgInNewBackgroundImages.backgroundImage;
            adjustedImg.backgroundImageName = imgInNewBackgroundImages.backgroundImageName;
            adjustedImg.isImage = imgInNewBackgroundImages.isImage ? true : false;
        }
    });
    return adjustedBackgroundImages;
}