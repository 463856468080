export const convertFontStylesheets = () => {
    const allStylesheets = Array.from(document.styleSheets).filter(item => item.href);
    try {
        for (let stylesheet of allStylesheets) {
            let rules = stylesheet.cssRules;
            if (!rules) throw 'null rules';
        }
    } catch (e) {
        if (e.name === 'SecurityError' || e === 'null rules') {
            for (let stylesheet of allStylesheets) {
                stylesheet.ownerNode.parentNode.removeChild(stylesheet.ownerNode);
                fetch(stylesheet.href).then(resp => resp.text()).then(css => {
                    let style = document.createElement('style');
                    style.innerHTML = css;
                    document.head.appendChild(style);
                });
            }
        }
    }
}