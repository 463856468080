import { useState, useRef } from 'react';

const Resize = ({children, initialWidth, callback}) => {
    const [elementWidth, setElementWidth] = useState(initialWidth);
    const resize = useRef(null);
    const startingX = useRef(0);


    const handleMouseDown = (e) => {
        // starting x value of resize ref
        const startingXVal = resize.current.getBoundingClientRect().x;
        document.body.style.cursor = "grabbing";
        document.body.style.userSelect = "none";
        startingX.current = startingXVal;
        document.addEventListener('mousemove', mouseMoving);
        document.addEventListener('mouseup', endMouseMoving);
    }

    const mouseMoving = (e) => {
        const difference = startingX.current - e.clientX; 
        setElementWidth(elementWidth + difference);
        // Old, but might need to reference this in the future
        // 16 accounts for the margin of the ShapeArea (0.5rem) + another 0.5rem for even margin
        callback(elementWidth + difference);
    }

    const endMouseMoving = (e) => {
        document.body.style.cursor = "default";
        document.body.style.userSelect = "auto";
        document.removeEventListener('mousemove', mouseMoving);
        document.removeEventListener('mouseup', endMouseMoving);
    }

    return (
        <div className="Resize" ref={resize} style={{width: elementWidth + "px"}}>
            <div className="resize-handle" onMouseDown={handleMouseDown}></div>
            {children}
        </div>
    )
}

export default Resize;