import { useContext } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import DirectoryContents from './DirectoryContents';

const Uploads = () => {
    const firebase = useContext(FirebaseContext);

    return (
        <div className="Uploads">
            {firebase.currentUser &&
                <DirectoryContents />
            }
        </div>
    )
}

export default Uploads;