import { useState, useContext } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import { FirebaseContext } from '../context/FirebaseContext';
import WebFont from 'webfontloader';
import ListItemWithSubmenu from './File/ListItemWithSubmenu';
import clone from '../../utils/_clone';
import { MdDelete } from 'react-icons/md';
import SubmenuBox from './SubmenuBox';

const ImportFont = () => {
    const shapesContext = useContext(ShapesContext);
    const firebase = useContext(FirebaseContext);
    const [fontValue, setFontValue] = useState('');
    const [loadError, setLoadError] = useState('');

    const handleChange = (e) => {
        setFontValue(e.target.value);
    }

    const handleImportFont = () => {
        setLoadError('');
        try {
            WebFont.load({
                google: {
                    families: [fontValue]
                },
                fontinactive: (fontValue, fvd) => {
                    setLoadError(fontValue);
                },
                fontactive: (fontValue, fvd) => {
                    shapesContext.setImportedFonts(oldFonts => {
                        const newFonts = [...oldFonts];
                        newFonts.push(fontValue);
                        const fontSet = Array.from(new Set(newFonts));
                        firebase.methods.updateFonts(fontSet);
                        return fontSet;
                    });
                }
            });
        } catch {
            setLoadError(fontValue);
        }
        setFontValue('');
    }

    const removeImportedFont = (font) => {
        shapesContext.setImportedFonts(oldImportedFonts => {
            const newImportedFonts = clone(oldImportedFonts);
            newImportedFonts.splice(newImportedFonts.indexOf(font), 1);
            firebase.methods.updateFonts(newImportedFonts);
            return newImportedFonts;
        });
    }



    return (
        <div className="ImportFont">
            <div className="fonts-list">
                {shapesContext.importedFonts.map(font => (
                    <div key={font} className="font-preview-list-item">
                        <div className="font-preview" style={{fontFamily: font}}>font-family: '{font}';</div>
                        <button onClick={() => removeImportedFont(font)}><MdDelete /></button>
                    </div>
                ))}
            </div>
            <ListItemWithSubmenu label="Import Font" customClass="import-font">
                <SubmenuBox className="import-font-box">
                    <div className="import-box">
                        <div className="directions">You can import fonts from <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">Google Fonts</a>. Paste the name of the font to import it. Use font-family and the name of the font in your styles.</div>
                        <input className="import-font-input" type="text" placeholder={`Roboto`} value={fontValue} onChange={handleChange}></input>
                        <button className="confirm-import" onClick={handleImportFont}>Import</button>
                    </div>
                    {loadError &&
                        <div className="load-error">There was an error loading {loadError}.</div>
                    }
                </SubmenuBox>
            </ListItemWithSubmenu>
        </div>
    )
}

export default ImportFont;