import useComponentVisible from '../../hooks/useComponentVisible';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const ListItemWithSubmenu = ({label, children, customClass, onOpenSubmenu, openDirection, overrideComponentVisible}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const handleOpenSubmenu = (e) => {
        if (typeof onOpenSubmenu === 'function') {
            onOpenSubmenu(true);
        }
        const label = e.target.closest('.file-list-item');
        if (label) {
            setIsComponentVisible(!isComponentVisible);
        }
    }

    return (
        <div className={`ListItemWithSubmenu ${customClass || ''}`} ref={ref}>
            <div className={`file-list-item ${(isComponentVisible) ? 'editing-list-item' : ''}`} onClick={handleOpenSubmenu}>
                {openDirection === 'left' && <BsChevronLeft />}<span className="list-item-text">{label}</span>{openDirection !== 'left' && <BsChevronRight />}
            </div>
            <div className={`submenu-wrapper ${openDirection === 'left' ? 'left-open-direction' : ''}`} style={{display: isComponentVisible ? 'flex' : 'none'}}>
                {children}
            </div>
        </div>
    )
}

export default ListItemWithSubmenu;