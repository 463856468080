import { useContext, useState } from 'react';
import { FirebaseContext } from '../context/FirebaseContext';
import useComponentVisible from '../hooks/useComponentVisible';
import ListItemWithSubmenu from './File/ListItemWithSubmenu';
import SubmenuInput from './File/SubmenuInput';
import Loading from '../Loading';
import { ShapesContext } from '../context/ShapesContext';

const LogInArea = () => {
    const { ref, setIsComponentVisible, openNavMenu } = useComponentVisible(false, "LogInArea", () => setError(null)); 
    const firebaseContext = useContext(FirebaseContext);
    const shapesContext = useContext(ShapesContext);
    const [error, setError] = useState(null);
    const firebase = firebaseContext.app;
    const currentUser = firebaseContext.currentUser;

    const handleLogOut = () => {
        firebase.auth().signOut();
    }

    const handleOpenMenu = (e) => {
        const navMenu = e.target.closest('.log-in-area-nav-menu');

        if (!navMenu) {
            if (openNavMenu === 'LogInArea') {
                setIsComponentVisible(false);
            } else {
                setIsComponentVisible('LogInArea');
            }
        }
    }

    const handleDeleteAccount = async (value) => {
        firebaseContext.setLoading('delete-account');
        if (value !== 'delete') {
            setError("You did not type 'delete'");
        } else {
            const deleteAccount = firebaseContext.functions.httpsCallable('deleteAccount');
            const deleteAccountProcess = await deleteAccount(currentUser.uid);
            if (deleteAccountProcess) {
                const userDocRef = firebaseContext.db.collection('user').doc(currentUser.uid);
                await userDocRef.delete();
                localStorage.clear();
                handleLogOut();
                shapesContext.resetAllShapes();
                firebaseContext.methods.resetAllState();
            } else {
                setError('There was an error deleting your account. Try again or contact us for assistance.');
            }
        }
        firebaseContext.setLoading(null);
    }

    return (
        <div className="LogInArea">
            {currentUser && 
                <div className="logged-in-container" ref={ref}>
                    <div className="user-info" onClick={handleOpenMenu}>
                        <img className="profile-picture" src={currentUser.photoURL} alt="" />
                    </div>
                    <div className="log-in-area-nav-menu" style={(openNavMenu === 'LogInArea') ? {display: 'flex'} : {display: 'none'}}>
                        <ListItemWithSubmenu label="Account settings" openDirection="left">
                            <ListItemWithSubmenu customClass="delete-account-nav-item" label="Delete account" openDirection="left" overrideComponentVisible={'delete-menu'}>
                                <SubmenuInput onConfirm={handleDeleteAccount} placeholder="delete" confirmLabel="Delete account" inputLabel="Type 'delete' to delete your account. This will delete all of your projects, uploads, and account data.">
                                    {error &&
                                        <div className="delete-account-error-message">{error}</div>
                                    }
                                    {firebaseContext.loading === "delete-account" &&
                                        <Loading />
                                    }
                                </SubmenuInput>
                            </ListItemWithSubmenu>
                        </ListItemWithSubmenu>
                        <div className="file-list-item right-text-align" onClick={handleLogOut}>Log out</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default LogInArea;