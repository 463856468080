import { useContext } from 'react';
import useComponentVisible from '../hooks/useComponentVisible';
import { FirebaseContext } from '../context/FirebaseContext';

const NavItem = ({children, label}) => {
    const firebase = useContext(FirebaseContext);
    const { ref, openNavMenu, setIsComponentVisible } = useComponentVisible(false, label);

    const handleOpenMenu = (e) => {
        const navMenu = e.target.closest('.nav-menu-wrapper');

        if (!navMenu) {
            if (openNavMenu === label) {
                setIsComponentVisible(false);
            } else {
                setIsComponentVisible(label);
            }
        }
    }

    const isUnsavedAndUpload = !firebase.documentId && label === 'Uploads';

    return (
        <div className={`NavItem ${openNavMenu === label && !isUnsavedAndUpload ? 'open-nav-item' : ''}`} ref={ref}>
            <div className="nav-item-label" onClick={handleOpenMenu}>{label}</div>
            <div className={`nav-menu-wrapper ${label || 'default'}-nav-wrapper`} style={(openNavMenu === label) ? {display: 'flex'} : {display: 'none'}}>
                {children}
            </div>
        </div>
    )
}

export default NavItem;