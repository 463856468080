import { useContext, useEffect, useState } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import Tooltip from '../Tooltip';
import useComponentVisible from '../hooks/useComponentVisible';

const StylesList = ({onConfirm}) => {
    const shapesContext = useContext(ShapesContext);
    const [selectedClassId, setSelectedClassId] = useState(null);
    const {ref, isComponentVisible} = useComponentVisible(true);

    const styleListItem = (style) => {
        const fixedNoInstance = style.classId.replace('_instance', '');
        return style.style ?
        <Tooltip key={style.classId} text={style.style} openFrom="left" followMouse={true} className="in-style-list">
            <div className="tooltip-action">
                <div className={`style-list-item ${selectedClassId === style.classId ? 'selected-style-list-item' : ''}`} onClick={() => setSelectedClassId(style.classId)}>
                    {fixedNoInstance}
                    <button className="style-list-item-confirm button-style" style={{visibility: selectedClassId === style.classId ? 'visible' : 'hidden'}} onClick={() => onConfirm(selectedClassId)}>Use</button>
                </div>
            </div>
        </Tooltip> : ''
    }

    useEffect(() => {
        if (!isComponentVisible) {
            shapesContext.handleCancelReplaceStyles();
        }
    });

    return (
        <div className="StylesList" ref={ref}>
            <div className="style-list">
                {shapesContext.styles.map(style => styleListItem(style))}
                {shapesContext.individualStyles.map(style => styleListItem(style))}
            </div>
        </div>
    )
}

export default StylesList;