import { useContext, useEffect, useState } from 'react';
import { FirebaseContext, uploadsPerPage } from '../../context/FirebaseContext';
import ProjectListBrowser from './ProjectListBrowser';
import { BsFolderSymlinkFill } from 'react-icons/bs';
import { MdOpenInNew, MdDelete, MdFormatListBulleted, MdFolder, MdAddAPhoto } from 'react-icons/md';
import ActionSubmenu from '../ActionSubmenu';
import Loading from '../../Loading';
import SubmenuBox from '../SubmenuBox';
import ImageUpload from './ImageUpload';
import Tooltip from '../../Tooltip';


const DirectoryContents = () => {
    const firebase = useContext(FirebaseContext);
    const [openSubmenu, setOpenSubmenu] = useState({name: null, action: null});
    const [top, setTop] = useState(0);

    const slicePage = (array, page) => {
        return array.slice(0).reverse().slice((page * uploadsPerPage) - uploadsPerPage + firebase.tempImageData.length, (page * uploadsPerPage) + firebase.tempImageData.length)
    }

    const showFolders = () => {
        firebase.setCurrentDirectory(null);
        firebase.setCurrentDirectoryProjectData(null);
        firebase.setCurrentDirectoryUploadData([]);
        firebase.setTempImageData([]);
        firebase.setShowProjectList(true);
        setOpenSubmenu({name: null, action: null});
    }

    const showDirectoryContents = async (id) => {
        firebase.setLoading(`open-project-item-${id}`);
        const singleProjectData = await firebase.methods.getSingleProjectData(id);
        const currentDirectoryUploadItems = await firebase.methods.getImagesFromUploadData(singleProjectData.uploads.slice(0).reverse().slice(0,uploadsPerPage));
        firebase.setCurrentDirectoryUploadData(currentDirectoryUploadItems);
        firebase.setCurrentDirectory(id, singleProjectData);
        firebase.setCurrentDirectoryProjectData(singleProjectData);
        firebase.setShowProjectList(false);
        firebase.setUploadPage(1);
        firebase.setLoading(null);
    }

    const handleMoveImage = async (selectedProject) => {
        if (openSubmenu.name) {
            await firebase.methods.moveUpload(selectedProject, openSubmenu.name);
            firebase.setShowProjectList(false);
        }
    }

    const handleDeleteItem = (itemName) => {
        setOpenSubmenu({name: null, action: null});
        firebase.methods.deleteUpload(itemName);
    }

    const openActionSubmenu = (itemName, action) => {
        setOpenSubmenu({name: itemName, action});
    }

    const getResetComponentVisible = (data) => {
        setOpenSubmenu({name: null, action: null});
    }

    const handleLoadMore = async () => {
        firebase.setLoading('load-more-uploads');
        firebase.setUploadPage(firebase.uploadPage + 1);
        const uploadsSlice = slicePage(firebase.currentDirectoryProjectData.uploads, firebase.uploadPage + 1);
        const currentDirectoryUploadItems = await firebase.methods.getImagesFromUploadData(uploadsSlice);
        const newUploadData = firebase.currentDirectoryUploadData.concat(currentDirectoryUploadItems);
        firebase.setCurrentDirectoryUploadData(newUploadData);
        firebase.setLoading(null);
    }

    const filteredListOfProjects = firebase.listOfProjects.filter(item => item.id !== firebase.currentDirectory);

    let uploadSlice = [];
    if (firebase.currentDirectoryProjectData && firebase.currentDirectoryProjectData.uploads) {
        uploadSlice = slicePage(firebase.currentDirectoryProjectData.uploads, firebase.uploadPage + 1);
    }

    const moveDelete = (item) => { 
        return (<>
            {openSubmenu.action === "move" && openSubmenu.name === item.name &&
                <ActionSubmenu resetComponentVisible={getResetComponentVisible}>
                    <ProjectListBrowser 
                        list={filteredListOfProjects}
                        onClickMove={(selectedProject) => handleMoveImage(selectedProject)} 
                    />
                </ActionSubmenu>
            }
            {openSubmenu.action === "delete" && openSubmenu.name === item.name &&
                <ActionSubmenu 
                    resetComponentVisible={getResetComponentVisible} 
                    message={`Confirm Delete of ${openSubmenu.name}`} 
                    onClick={() => handleDeleteItem(openSubmenu.name)}
                    escapeMessage={true}
                />
            }
        </>);
    }

    const dirItemRightSide = (item, hasOpen = true) => {
        return (
            <div className="directory-item-right-side">
                <div className="directory-item-name">{item.name}</div>
                <div className="directory-item-actions">
                    {filteredListOfProjects.length > 0 &&
                        <button onClick={() => openActionSubmenu(item.name, "move")} className="directory-action" title="Move"><BsFolderSymlinkFill /></button>
                    }
                    {hasOpen &&
                        <a className="button-style directory-action" href={item.url} target="_blank" rel="noopener noreferrer" title="Open"><MdOpenInNew /></a>
                    }
                    <button onClick={() => openActionSubmenu(item.name, "delete")} className="directory-action" title="Delete"><MdDelete /></button>
                </div>
            </div>
        );
    }

    return (
        <div className="DirectoryContents">
            {firebase.currentDirectory &&
                <div className="directory-info-wrapper">
                    {firebase.currentDirectoryProjectData &&
                        <div className="current-directory"><span className="item-name-left-folder"><MdFolder/></span>{firebase.currentDirectoryProjectData.title}</div>
                    }
                    <div className="directory-info-right">
                        <div className="button-wrapper">
                            <Tooltip text="Projects list">
                                <button onClick={showFolders}><MdFormatListBulleted /></button>
                            </Tooltip>
                        </div>
                        <div className="button-wrapper">
                            <Tooltip text="Upload an image" openFrom="top">
                                <button className="upload-image-button" onClick={() => openActionSubmenu("add-new-image", "upload")}><MdAddAPhoto /></button>
                            </Tooltip>
                        </div>
                    </div>
                </div>            
            }

            {openSubmenu.action === "upload" && openSubmenu.name === 'add-new-image' &&
                <ActionSubmenu resetComponentVisible={getResetComponentVisible}>
                    <SubmenuBox>
                        <ImageUpload />
                    </SubmenuBox>
                </ActionSubmenu>
            }
            <div className="list-of-directory-items overflowing-item" onScroll={(e) => setTop(e.target)}>
                {firebase.tempImageData.map(image => (
                    <div className="directory-item image-preview" key={image.name} style={(image) ? {display: "flex"} : {display: "none"} }>
                        <div className="directory-item-list-content-wrapper">
                            <div className="directory-item-left-side">
                                <div className="directory-item-image"><img className="uploaded-image" src={image.url}/></div>
                            </div>
                            {dirItemRightSide(image)}
                        </div>
                        {moveDelete(image)}
                    </div>
                ))}
                {firebase.currentDirectoryUploadData.map(item => (
                    <div key={item.url} className="directory-item">
                        <div className="directory-item-list-content-wrapper">
                            <div className="directory-item-left-side">
                                <div className="directory-item-image"><img className="uploaded-image" src={item.thumbnailURL || item.url}/></div>
                            </div>
                            {dirItemRightSide(item)}
                        </div>
                        {moveDelete(item)}
                    </div>
                ))}
                {!firebase.showProjectList && uploadSlice.length > 0 && !firebase.loading &&
                    <div className="load-more-uploads-wrapper">
                        <button onClick={handleLoadMore} className="load-more-uploads">Load More</button>
                    </div>
                }
                {firebase.loading === 'load-more-uploads' &&
                    <div className="loading-uploads-wrapper">
                        <Loading />
                    </div>
                }
            </div>
            {firebase.showProjectList &&
                <div className="project-list overflowing-item">
                    {firebase.listOfProjects.slice(0).reverse().map(projectItem => (
                        <div className="project-list-item" key={projectItem.id}>
                            <span className="project-item-title">{projectItem.title}</span>
                            {firebase.loading !== `open-project-item-${projectItem.id}` &&
                                <button className="open-project-item" onClick={() => showDirectoryContents(projectItem.id)}>Open</button>
                            }
                            {firebase.loading === `open-project-item-${projectItem.id}` &&
                                <Loading />
                            }
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default DirectoryContents;