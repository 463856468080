const enableTab = (e) => {
    e.preventDefault();
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;

    if (start !== end) return;

    // set textarea value to: text before caret + tab + text after caret
    e.target.value = e.target.value.substring(0, start) +
      "\t" + e.target.value.substring(end);

    // put caret at right position again
    e.target.selectionStart =
      e.target.selectionEnd = start + 1;
}

export default enableTab;

export const pressEnter = (e) => {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.substring(0, start) +
    "\n" + e.target.value.substring(end);

  e.target.selectionStart =
    e.target.selectionEnd = start + 1;

  e.target.scrollTop = e.target.scrollTop + 20;
}

export const pressBackspace = (e) => {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.substring(0, start - 1) + e.target.value.substring(end);

  e.target.selectionStart =
    e.target.selectionEnd = start - 1;
}