import { useState, useRef, useContext } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import Loading from '../../Loading';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import clone from '../../../utils/_clone';

const acceptableCharacters = /^[a-zA-Z-_.\s0-9]+$/;

const ImageUpload = () => {
    const firebase = useContext(FirebaseContext);
    const uploadFile = useRef(null);
    
    const currentUser = firebase.currentUser;

    const [loading, setLoading] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [imageName, setImageName] = useState('');
    const [initializedUploadImage, setInitializedUploadImage] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const clickUploadImage = () => {
        uploadFile.current.click();
    }

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            const splitImageName = e.target.files[0].name.split('.');
            let formattedImageName = splitImageName[0];
            if (formattedImageName.length > 20) {
                formattedImageName = formattedImageName.substring(0, 20);
            }
            loadPreviewImage(e.target.files[0]);
            setImageName(formattedImageName);
            setInitializedUploadImage(true);
        }
    }

    const loadPreviewImage = (file) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setPreviewImage(reader.result);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleFileUpload = async () => {
        const targetRef = uploadFile.current;
        if (imageName.length === 0) {
            setImageError(`Please enter a name for your image.`);
            return;
        }
        if (targetRef.files[0]) {
            const image = targetRef.files[0];
            if (image.size > 8388608) {
                setImageError('Error: Max file size 8MB.');
            } else {
                setLoading(true);
                let currentDirectory = firebase.currentDirectory;
                if (!currentDirectory) {
                    currentDirectory = await firebase.methods.saveProject();
                }
                setImageError(false);

                const splitImageName = image.name.split('.');
                let formattedImageName = splitImageName[0];
                if (imageName) {
                    formattedImageName = imageName;
                }
                if (formattedImageName.length > 20) {
                    formattedImageName = formattedImageName.substring(0, 20);
                }

                try {
                    // For if the image name already exists (every image name needs to be unique)
                    
                    const hasDownloadURL = await firebase.methods.singleImageRef(formattedImageName).getDownloadURL();
                    if (hasDownloadURL) {
                        setImageError(`There is already an image with the name "${formattedImageName}." Please use a unique name.`);
                        setLoading(false);
                        return;
                    } else {
                        firebase.methods.addImageLoaded(hasDownloadURL, formattedImageName);
                    }
                } catch (err) {
                    // All good, since the object doesn't exist
                }

                try {
                    // Check if user has over 50 images
                    const images = await firebase.methods.imagesFolderRef().listAll();
                    const numImages = images.items.length;
                    if (numImages >= 50) {
                        setImageError('The maximum number of images you can upload is 50. Delete an old image to upload this image.');
                        setLoading(false);
                        return;
                    }
                } catch (err) {
                    setImageError('Could not upload your image');
                    setLoading(false);
                    return;
                }


                try {
                    
                    const storageRef = firebase.methods.singleImageRef(formattedImageName);

                    const uploadTask = await storageRef.put(image);
                    await storageRef.updateMetadata({
                        customMetadata: {
                            documentId: currentDirectory
                        }
                    });

                    const downloadURL = await uploadTask.ref.getDownloadURL();
                    firebase.methods.addImageLoaded(downloadURL, formattedImageName);
                    firebase.setTempImageData(oldData => {
                        const newData = clone(oldData);
                        newData.unshift({url: downloadURL, name: formattedImageName});
                        return newData;
                    });
                    const meta = await uploadTask.ref.getMetadata();
                    setInitializedUploadImage(false);
                    const thumbnailPathEnd = (splitImageName[1] === "svg" || splitImageName[1] === "gif") ? formattedImageName : 'thumbnails/' + formattedImageName + '_200x200';
                    const uploadObject = {name: formattedImageName, updated: meta.updated, currentDirectory, thumbnailPath: `user/${currentUser.uid}/images/${thumbnailPathEnd}`};
                    // Save the upload info to the document in Firestore /user/projects/currentDirectory
                    await firebase.methods.updateProjectListOfUploads(currentDirectory, uploadObject);
                    // Get project data to save in firebase context state
                    const projectData = await firebase.methods.getSingleProjectData(currentDirectory);
                    firebase.setCurrentDirectoryProjectData(projectData);
                    
                    setLoading(false);
                    setImageName('');
                    targetRef.value = '';
                    setPreviewImage(null);

                    const numUploadsLeft = await firebase.methods.getUploadsRemaining();
                    firebase.setUploadsRemaining(numUploadsLeft);
                } catch (err) {
                    setLoading(false);
                    setImageName('');
                    targetRef.value = '';
                    setPreviewImage(null);
                }
            }
        }
    }

    const handleImageNameChange = (e) => {
        if (e.target.value.length > 20) return;
        setImageName(e.target.value);
    }

    const handleKeyDown = (e) => {
        const matchCharacter = e.key.match(acceptableCharacters);
        if (!matchCharacter) {
            e.preventDefault();
            return;
        }
    }

    return (
        <div className="ImageUpload">
            <div className="image-upload-button" onClick={clickUploadImage}><span className="upload-text">Choose File</span><BsFillPlusCircleFill/></div>
            <input ref={uploadFile} style={{display: 'none'}} type="file" accept="image/*" onChange={handleFileChange} />
            {initializedUploadImage &&
                <div className="image-upload-controls">
                    <div className="name-preview-line">
                        <span className="name-label">Name: </span>
                        <input className="uploaded-image-input" type="text" value={imageName} onChange={handleImageNameChange} onKeyDown={handleKeyDown} />
                        {previewImage &&
                            <img src={previewImage} className="preview-image" />
                        }
                    </div>
                    <div className="upload-button-line">
                        <div className="num-uploads-left">{firebase.uploadsRemaining}/50 uploads remaining</div>
                        <button onClick={handleFileUpload}>Upload</button>
                    </div>
                </div>
            }
            {loading &&
                <div className="loading-uploads-wrapper">
                    <Loading />
                </div>
            }
            <div className="image-upload-error" style={(imageError) ? {display: "flex"} : {display: "none"}}>{imageError}</div>
        </div>
    )

}

export default ImageUpload;