import { useState } from 'react';

const ProjectListBrowser = ({onClickMove, list}) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleClick = (itemName) => {
        if (selectedItem === itemName) {
            setSelectedItem(null);
        } else {
            setSelectedItem(itemName);
        }
    }

    const handleClickMove = () => {
        onClickMove(selectedItem);
        setSelectedItem(null);
    }

    return (
        <div className="ProjectListBrowser">
            <div className="project-list-browser-headline">Select a project to move the upload to</div>
            <div className="project-list-browser-items">
                {list.map(projectItem => (
                    <div className={`project-list-item ${(selectedItem === projectItem.id) ? 'selected-project-list-item' : ''}`} key={projectItem.id} onClick={() => handleClick(projectItem.id)}>
                        <div className="project-list-item-title">{projectItem.title}</div>
                        <button className="hidden-button">X</button>
                        {selectedItem === projectItem.id && 
                            <div className="button-wrapper">
                                <button onClick={handleClickMove}>Move Here</button>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProjectListBrowser;