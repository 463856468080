import { useState, createContext, useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import WebFont from 'webfontloader';
import clone from '../../utils/_clone';
import { convertFontStylesheets } from '../../utils/_download-fixes';

const ShapesContext = createContext(null);
export { ShapesContext }

let fontsToUse = (localStorage.getItem('fonts')) ? JSON.parse(localStorage.getItem('fonts')) : [];
fontsToUse = fontsToUse.filter(font => font);
if (fontsToUse.length > 0) {
    WebFont.load({
        google: {
            families: fontsToUse
        },
        fontactive: () => {
            convertFontStylesheets()
        }
    });
}




const defaultShapes = [
    {children: ["_p2adnlpgm", "_7n3ulc93o", "_0n2m1rvyb"], classId: ".container", id: "_1fc7k2pd9", indents: 0, instance: 1, parent: null, selectionBorderVisible: true, visible: true},
    {children: [], classId: ".header", id: "_p2adnlpgm", indents: 1, instance: 1, parent: "_1fc7k2pd9", selectionBorderVisible: true, visible: true},
    {children: [], classId: ".logo", id: "_7n3ulc93o", indents: 1, instance: 1, parent: "_1fc7k2pd9", selectionBorderVisible: true, visible: true},
    {children: [], classId: ".caption", id: "_0n2m1rvyb", indents: 1, instance: 1, parent: "_1fc7k2pd9", selectionBorderVisible: true, visible: true},
];

const defaultCodeEditor = ".container\n\t.header\n\t.logo\n\t.caption";

const defaultStyles = [
    {backgroundImage: "", backgroundImageName: "", classId: ".container", lineColor: "hsl(26.878498625297116, 100%, 30%)", style: "", text: "", isImage: false},
    {backgroundImage: "", backgroundImageName: "", classId: ".header", lineColor: "hsl(202.50838792504894, 100%, 30%)", style: "font-size: 4rem;\nfont-weight: bold;\nmargin-bottom: 1rem;\ntext-align: center;", text: "", isImage: false},
    {backgroundImage: "", backgroundImageName: "", classId: ".logo", lineColor: "hsl(39.00828831654016, 100%, 30%)", style: "width: 10rem;\nheight: 10rem;", text: "", isImage: false},
    {backgroundImage: "", backgroundImageName: "", classId: ".caption", lineColor: "hsl(139.4437429392786, 100%, 30%)", style: "text-align: center;\nline-height: 1.4;", text: "", isImage: false},
];

const defaultIndividualStyles = [
    {backgroundImage: "", classId: ".container._instance1", style: "$flex;\n$acenter;\n$jcenter;\n$column;\nbackground: #e6e6fa;\npadding: 3rem;", text: "", isImage: false},
    {backgroundImage: "", classId: ".header._instance1", style: "text: Welcome to UltraCSS;", 
        text: ["text: Welcome to UltraCSS;", "Welcome to UltraCSS"], isImage: false
    },
    {backgroundImage: "", classId: ".logo._instance1", style: "img: https://css-mockup.web.app/ultralogosymbol.svg;\nmargin-bottom: 1rem;", text: "", isImage: true},
    {backgroundImage: "", classId: ".caption._instance1", style: "text: \nCreate your first project by clicking File -> New Project. \n<br>\nOnce you create a project, uploads for the project will be enabled. \n<br>\nIf you need help, click the Help button in the navigation menu.;", 
        text: ["text: \nCreate your first project by clicking File -> New Project. \n<br>\nOnce you create a project, uploads for the project will be enabled. \n<br>\nIf you need help, click the Help button in the navigation menu.;", 
            "Create a project by clicking File -> New Project. \n<br>\nOnce you create a project, uploads for the project will be enabled. \n<br>\nIf you need help, click the Help button in the navigation menu."
        ], isImage: false
    }
]

const defaultBackgroundImages = [
    {classId: ".container", backgroundImage: "", backgroundImageName: "", isImage: false},
    {classId: ".header", backgroundImage: "", backgroundImageName: "", isImage: false},
    {classId: ".logo", backgroundImage: "", backgroundImageName: "", isImage: false},
    {classId: ".caption", backgroundImage: "", backgroundImageName: "", isImage: false},
]

const defaultIndividualBackgroundImages = [
    {classId: ".container._instance1", backgroundImage: "", backgroundImageName: "", isImage: false},
    {classId: ".header._instance1", backgroundImage: "", backgroundImageName: "", isImage: false},
    {classId: ".logo._instance1", backgroundImage: "https://ultracss.com/ultralogosymbol.svg", backgroundImageName: "", isImage: true},
    {classId: ".caption._instance1", backgroundImage: "", backgroundImageName: "", isImage: false},
]

const defaultVariables = `$flex = "display: flex";\n$acenter = "align-items: center";\n$jcenter = "justify-content: center";\n$column = "flex-direction: column";`;

const ShapesProvider = ({children}) => {

    const [shapes, setShapes] = useState(defaultShapes);
    const [styles, setStyles] = useState(defaultStyles);
    const [individualStyles, setIndividualStyles] = useState(defaultIndividualStyles);
    const [backgroundImages, setBackgroundImages] = useState(defaultBackgroundImages);
    const [individualBackgroundImages, setIndividualBackgroundImages] = useState(defaultIndividualBackgroundImages);
    const [codeEditorValue, setCodeEditorValue] = useState(defaultCodeEditor);
    const [importedFonts, setImportedFonts] = useState(fontsToUse);
    const [variables, setVariables] = useState(defaultVariables);
    const [codeEditorHistory, setCodeEditorHistory] = useState([defaultCodeEditor]);

    // const [shapes, setShapes] = useLocalStorage('shapes', defaultShapes);
    // const [styles, setStyles] = useLocalStorage('styles', defaultStyles);
    // const [individualStyles, setIndividualStyles] = useLocalStorage('individual-styles', defaultIndividualStyles);
    // const [backgroundImages, setBackgroundImages] = useLocalStorage('background-images', defaultBackgroundImages);
    // const [individualBackgroundImages, setIndividualBackgroundImages] = useLocalStorage('individual-background-images', defaultIndividualBackgroundImages);
    // const [codeEditorValue, setCodeEditorValue] = useLocalStorage('code-editor', defaultCodeEditor);
    // const [importedFonts, setImportedFonts] = useLocalStorage('fonts', fontsToUse);
    // const [variables, setVariables] = useLocalStorage('variables', defaultVariables);
    // const [codeEditorHistory, setCodeEditorHistory] = useLocalStorage('code-editor-history', [defaultCodeEditor]);
    
    const [lineSelected, setLineSelected] = useState([]);
    const [currentlyReplacingStyles, setCurrentlyReplacingStyles] = useState(null);
    const [useDifferentStyles, setUseDifferentStyles] = useState(null);
    
    // replaceStyles[n][0] = initiator classId
    // replaceStyles[n][0].styleObj = initiator classId style obj
    // replaceStyles[n][0].backgroundObj = initiator classId background obj

    // replaceStyles[n][1] = target classId
    // replaceStyles[n][1].styleObj = target classId style obj
    // replaceStyles[n][1].backgroundObj = target classId background obj
    const defaultReplaceStyles = [[
        {styleObj: {classId: null}, backgroundObj: null}, 
        {styleObj: {classId: null}, backgroundObj: null}
    ]];

    const [replaceStyles, setReplaceStyles] = useState(defaultReplaceStyles);

    const resetReplaceStyles = () => {
        setReplaceStyles(defaultReplaceStyles);
    }

    const isInstance = (classId) => classId.includes('._instance');

    const handleCancelReplaceStyles = () => {
        setReplaceStyles(oldReplaceStyles => {
            const newReplaceStyles = clone(oldReplaceStyles);
            newReplaceStyles.shift();
            return newReplaceStyles;
        });
        setCurrentlyReplacingStyles(null);
        setUseDifferentStyles(null);
    }

    const resetAllShapes = () => {
        setShapes(defaultShapes);
        setStyles(defaultStyles);
        setIndividualStyles(defaultIndividualStyles);
        setBackgroundImages(defaultBackgroundImages);
        setIndividualBackgroundImages(defaultIndividualBackgroundImages);
        setCodeEditorValue(defaultCodeEditor);
        setImportedFonts(fontsToUse);
        setVariables(defaultVariables);
        setLineSelected([]);
        setCodeEditorHistory([defaultCodeEditor]);
        resetReplaceStyles();
    }

    const undoStyleState = (oldStyles, originalStyle) => {
        const newStyles = clone(oldStyles);
        if (!originalStyle) return newStyles;
        const newStyle = newStyles.find(item => item.classId === originalStyle.classId);
        if (newStyle) {
            Object.assign(newStyle, originalStyle);
        }
        return newStyles;
    }

    const undoReplaceStyles = () => {
        const initiatorOriginalStyle = replaceStyles[0][0].styleObj;
        const initiatorOriginalBackground = replaceStyles[0][0].backgroundObj;
        const targetOriginalStyle = replaceStyles[0][1].styleObj;
        const targetOriginalBackground = replaceStyles[0][1].backgroundObj;

        const canUndoAction = replaceStyles.length > 1 && initiatorOriginalStyle && targetOriginalStyle;
        if (!canUndoAction) return;

        const newIndividualStylesInitiator = undoStyleState(individualStyles, initiatorOriginalStyle);
        const newIndividualStyles = undoStyleState(newIndividualStylesInitiator, targetOriginalStyle);
        
        const newStylesInitiator = undoStyleState(styles, initiatorOriginalStyle);
        const newStyles = undoStyleState(newStylesInitiator, targetOriginalStyle);

        const newIndividualBGImagesInitiator = undoStyleState(individualBackgroundImages, initiatorOriginalBackground);
        const newIndividualBGImages = undoStyleState(newIndividualBGImagesInitiator, targetOriginalBackground);
        
        const newBGImagesInitiator = undoStyleState(backgroundImages, initiatorOriginalBackground);
        const newBGImages = undoStyleState(newBGImagesInitiator, targetOriginalBackground);

        setIndividualStyles(newIndividualStyles);
        setStyles(newStyles);
        setIndividualBackgroundImages(newIndividualBGImages);
        setBackgroundImages(newBGImages);

        setReplaceStyles(oldReplaceStyles => {
            const newReplaceStyles = clone(oldReplaceStyles);
            newReplaceStyles.shift();
            return newReplaceStyles;
        });
    }

    useEffect(() => {
        if (document.body.style.overflow !== "hidden" && window.innerWidth >= 768) {
            document.body.style.overflow = "hidden";
        }
        const meta = document.querySelector('meta[name="viewport"]');
        if (meta.getAttribute('content').includes('initial-scale=1')) {
            meta.setAttribute('content', 'width=device-width, initial-scale=0.5');
        }
    });

    return (
        <ShapesContext.Provider value={{
            shapes, setShapes,
            lineSelected, setLineSelected,
            codeEditorValue, setCodeEditorValue,
            styles, setStyles,
            individualStyles, setIndividualStyles,
            importedFonts, setImportedFonts,
            variables, setVariables,
            backgroundImages, setBackgroundImages,
            individualBackgroundImages, setIndividualBackgroundImages,
            codeEditorHistory, setCodeEditorHistory,
            replaceStyles, setReplaceStyles,
            resetAllShapes, resetReplaceStyles,
            currentlyReplacingStyles, setCurrentlyReplacingStyles,
            useDifferentStyles, setUseDifferentStyles,
            handleCancelReplaceStyles,
            undoReplaceStyles, isInstance
        }}>
            {children}
        </ShapesContext.Provider>
    )
}

export default ShapesProvider;