import { useContext, useState, useRef, useEffect } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';

const acceptableCharacters = /^[a-zA-Z-_.\s0-9]+$/;

const ProjectName = () => {
    const renameRef = useRef(null);
    const displayNameRef = useRef(null);
    const firebase = useContext(FirebaseContext);
    const [isRenaming, setIsRenaming] = useState(false);
    const [error, setError] = useState(null);
    const [originalName, setOriginalName] = useState('');

    const handleKeyDown = (e) => {
        const matchCharacter = e.key.match(acceptableCharacters);
        if (!matchCharacter) {
            e.preventDefault();
            return;
        }
    }

    const handleChangeProjectName = (e) => {
        if (e.target.value.length > 35) return;
        firebase.setProjectName(e.target.value);
    }

    const handleActivateRename = (e) => {
        setIsRenaming(true);
        setOriginalName(e.target.innerText);
    }

    const handleInputFocusOut = async (e) => {
        if (e.target.value.length === 0) {
            firebase.setProjectName(firebase.documentId);
        }
        const projectExists = await firebase.methods.doesProjectTitleExist(firebase.projectName);
        if (projectExists && originalName !== firebase.projectName) {
            setError('Error: Project title already exists');
            renameRef.current.focus();
            return;
        } else {
            setError(null);
        }
        setIsRenaming(false);
        const projectsRemaining = await firebase.methods.getProjectsRemaining();
        if (projectsRemaining > 0) {
            firebase.methods.saveProject(firebase.documentId);
            setOriginalName(e.target.value);
        }
    }

    useEffect(() => {
        if (isRenaming) {
            renameRef.current.focus();
        }
        const displayBox = displayNameRef.current.getBoundingClientRect();
        renameRef.current.style.width = displayBox.width + "px";

    });

    return (
        <div className="ProjectName">
            <div 
                className="project-name-display" 
                ref={displayNameRef} 
                style={(isRenaming) ? 
                    {visibility: 'hidden', position: 'absolute'} : 
                    {visibility: 'visible'}
                } 
                onClick={handleActivateRename}
                data-tip="Click to rename"
            >
                {firebase.projectName}</div>
            <input 
                className="project-name-input" 
                ref={renameRef} 
                value={firebase.projectName} 
                style={(isRenaming) ? {display: 'flex'} : {display: 'none'}} 
                onKeyDown={handleKeyDown} 
                onChange={handleChangeProjectName} 
                onBlur={handleInputFocusOut} 
            />
            {error &&
                <div className="project-name-error">{error}</div>
            }
        </div>
    )
}

export default ProjectName;