import { Link } from 'react-router-dom';

const HomeNav = ({children}) => {
    return (
        <div className="HomeNav">
            <nav className="home-nav">
                <div className="nav-left">
                    <div className="home-nav-item logo-container"><Link to="/" className="logo-link"><img className="logo" src="ultralogosymbol-white.svg"/><span className="logo-text">UltraCSS</span></Link></div>
                </div>
                <div className="nav-right">
                    {children}
                </div>
            </nav>
        </div>

    )
}

export default HomeNav;