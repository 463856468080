import { useState, useEffect, useRef, useContext } from 'react';
import { NavContext } from '../context/NavContext';

export default function useComponentVisible(initialIsVisible, navContextLabel, clickOutsideCallback) {
    const navContext = useContext(NavContext);
    const [isComponentVisible, setLocalIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);
    const clickedOnTarget = useRef(false);

    const setIsComponentVisible = (value) => {
        if (navContextLabel) {
            navContext.setOpenNavMenu(value);
        } else {
            setLocalIsComponentVisible(value);
        }
    }

    const handleClickOutside = (event) => {
        const selection = window.getSelection();
        const selectedText = selection.toString();
        const selectionNode = selection.anchorNode;
        const activelySelected = ref.current && selectedText && ref.current.contains(selectionNode);
        const variablesActive = Array.from(document.activeElement.classList).includes('variables-textarea');

        const doesNotContainTarget = ref.current && !ref.current.contains(event.target);
        
        const labelIsOpenNavMenu = (navContextLabel) ? navContext && navContext.openNavMenu === navContextLabel : true;

        if (ref.current && doesNotContainTarget && !activelySelected && !variablesActive && labelIsOpenNavMenu) {
            setIsComponentVisible(false);
            if (typeof clickOutsideCallback === 'function') {
                clickOutsideCallback();
            }
        }

    };

    useEffect(() => {
        if (isComponentVisible || navContextLabel === navContext?.openNavMenu) {
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible, clickedOnTarget, openNavMenu: navContext?.openNavMenu, setOpenNavMenu: navContext?.setOpenNavMenu };
}