const rawSplitLinesToShapeIndex = (linesArray, index) => {
    const valueWithoutWhitespace = linesArray.map((item, valueIndex) => ({classId: item.trim(), index: valueIndex})).filter(item => item.classId && item.classId[item.classId.length - 1] !== '.');
    const cleanIndex = valueWithoutWhitespace.findIndex(item => item.index === index);
    return cleanIndex;
}

export const findSingleRawLineSelected = (e) => {

    const allLines = e.target.value.split('\n');
    for (let [index, line] of allLines.entries()) {
        let previousLength = 0;
        for (let i = 0; i < index; i++) {
            // Add one for \n that gets split
            previousLength += allLines[i].length + 1;
        }
        const from = previousLength;
        const to = from + line.length;
        if (e.target.selectionStart >= from && e.target.selectionEnd <= to) {
            return {index, value: line, cleanIndex: rawSplitLinesToShapeIndex(allLines, index), from, to};
        }
    }
    return {index: null, value: null, cleanIndex: null};
}

export const findLineSelected = (e) => {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const allLines = e.target.value.split('\n');
    const linesSelected = [];
    const valueWithoutWhitespace = allLines.map((item, valueIndex) => ({classId: item.trim(), index: valueIndex})).filter(item => item.classId && item.classId[item.classId.length - 1] !== '.');
    for (let [index, line] of allLines.entries()) {
        let previousLength = 0;
        for (let i = 0; i < index; i++) {
            // Add one for \n that gets split
            previousLength += allLines[i].length + 1;
        }
        const from = previousLength;
        const to = from + line.length;

        // Find if selectionStart is between the to and from values
        // Or if selectionEnd is between the to and from values
        // Or if selectionStart is less than from and selectionEnd is greater than to

        const selectionStartBetween = start >= from && start <= to;
        const selectionEndBetween = end >= from && end <= to;
        const selectionFullyBetween = start <= from && end >= to;

        if ((selectionStartBetween || selectionEndBetween || selectionFullyBetween) && line.trim()) {
            const cleanIndex = valueWithoutWhitespace.findIndex(item => item.index === index)
            if (cleanIndex > -1) {
                linesSelected.push({index, value: line, cleanIndex, from, to, trimmedValue: line.trim()});
            }
        }
    }
    return linesSelected;
}

export const findShapeParent = (shapes, shapeIndex) => {
    const refShapeIndents = shapes[shapeIndex].indents;
    for (let i = shapeIndex; i >= 0; i--) {
        if (shapes[i].indents + 1 === refShapeIndents && shapes[i].value.trim()) {
            return {value: shapes[i].value, indents: shapes[i].indents, index: i};
        }
    }
    return null;
}

export const findShapeChildren = (shapes, shapeIndex) => {
    const refShapeIndents = shapes[shapeIndex].indents;
    const children = [];
    for (let i = shapeIndex + 1; i < shapes.length; i++) {
        if (shapes[i].indents > refShapeIndents || !shapes[i].value.trim()) {
            children.push(i);
        } else return children;
    }
    return children;
}

export const findShapeDirectChildren = (shapes, shapeIndex) => {
    const refShapeIndents = shapes[shapeIndex].indents;
    const shapeChildren = findShapeChildren(shapes, shapeIndex);
    const directChildren = [];
    for (let i = 0; i < shapeChildren.length; i++) {
        if (shapes[shapeChildren[i]].indents === refShapeIndents + 1 && shapes[shapeChildren[i]].value.trim()) {
            directChildren.push(shapeChildren[i]);
        }
    }
    return directChildren;
}

export const numberOfTabs = (text) => {
    var count = 0;
    var index = 0;
    while (text.charAt(index++) === "\t") {
      count++;
    }
    return count;
}

export const backwardsNumberOfTabs = (text) => {
    var count = 0;
    var index = text.length - 1;
    while (text.charAt(index--) === "\t") {
      count++;
    }
    return count;
}

export const removeDuplicates = (arr) => {
    return arr.filter((v,i,a)=>a.findIndex(t=>(t.classId===v.classId))===i);
}

export const sortClassesByLength = (array) => {
    return array.sort((x,y) => y.length - x.length);
}

export const getLineInstanceInShapes = (line, index, shapesContext, value) => {
    const valueWithoutWhitespace = value.map((item, valueIndex) => ({classId: item.trim(), index: valueIndex})).filter(item => item.classId && item.classId[item.classId.length - 1] !== ".");

    const instances = [];

    const lineInValueIndex = valueWithoutWhitespace.findIndex(item => item.index === index);
    const lineInValue = valueWithoutWhitespace[lineInValueIndex];

    if (!lineInValue) return -1;

    shapesContext.shapes.forEach((shape, indexOfShape) => {
        if (shape.classId === lineInValue.classId) {
            instances.push(indexOfShape);
        }
    });
    const indexOfClassId = instances.indexOf(lineInValueIndex);
    return indexOfClassId;
}

export function getRandomColor() {
    const color = "hsl(" + Math.random() * 360 + ", 100%, 30%)";
    return color;
}

export function getStringPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
}

export const findChildrenLines = (textareaSplit, lineValue, lineIndex) => {
    const refLineIndents = numberOfTabs(lineValue);
    const children = [];
    for (let i = lineIndex + 1; i < textareaSplit.length; i++) {
        if (numberOfTabs(textareaSplit[i]) > refLineIndents || !textareaSplit[i].trim()) {
            children.push(i);
        } else return children;
    }
    return children;
}

export const addPipesAtEachTab = (line) => {
    let formattedLine = line;
    const backwardsTabs = backwardsNumberOfTabs(line);
    if (backwardsTabs > 0) {
        formattedLine = line.slice(0, -(backwardsTabs));
    }
    const splitLine = formattedLine.split('');
    const addPipes = splitLine.map(character => {
        if (character === "\t") {
            character = "|\t";
        }
        return character;
    });
    addPipes.unshift('<span class="subtle-pre-text">');
    let indexOfFirstPeriod = addPipes.indexOf('.');
    if (indexOfFirstPeriod === -1) {
        indexOfFirstPeriod = 0;
    }
    addPipes.splice(indexOfFirstPeriod, 0, "</span>");
    //const removeEndTab = addPipes.slice(0, indexOfFirstPeriod + 1);
    const newLine = addPipes.join('');
    return newLine;
}

export const findLineParent = (splitLines, lineIndex) => {
    const refLineIndents = numberOfTabs(splitLines[lineIndex]);
    for (let i = lineIndex; i >= 0; i--) {
        if (numberOfTabs(splitLines[i]) + 1 === refLineIndents && splitLines[i].trim()) {
            return {value: splitLines[i], index: i}
        }
    }
    return {value: '', index: -1};
}

export const saveSelection = () => {
    if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            return sel.getRangeAt(0);
        }
    } else if (document.selection && document.selection.createRange) {
        return document.selection.createRange();
    }
    return null;
}

export const restoreSelection = (range) => {
    if (range) {
        if (window.getSelection) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.selection && range.select) {
            range.select();
        }
    }
}