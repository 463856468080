import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { NavContext } from '../../context/NavContext';
import ListItemWithSubmenu from './ListItemWithSubmenu';
import DownloadProject from './DownloadProject';
import SubmenuInput from './SubmenuInput';
import CompletedMessage from '../CompletedMessage';
import Loading from '../../Loading';
import ErrorMessage from '../../ErrorMessage';
import handleInputKeyDown from '../../../utils/_title-inputs';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShapesContext } from '../../context/ShapesContext';

const File = () => {
    const firebase = useContext(FirebaseContext);
    const navContext = useContext(NavContext);
    const shapesContext = useContext(ShapesContext);

    const [error, setError] = useState(null);

    const handleNewProject = async (title) => {
        firebase.setLoading('file-list-item-new-project');
        if (firebase.projectsRemaining <= 0) {
            setError('You have 0 projects remaining. Delete an old project to make a new project.');
            firebase.setLoading(false);
            return;
        }
        setError(null);
        const errors = await firebase.methods.titleErrors(title);
        if (errors) {
            console.error('error', errors);
            setError(errors);
            firebase.setLoading(false);
            return;
        }
        try {
            firebase.methods.newProject(title);
        } catch (err) {
            console.error(err);
            setError('There was an error creating the project.');
            firebase.setLoading(false);
            return;
        }
        if (firebase.currentUser) {
            try {
                const newDocId = await firebase.methods.saveProject(false, null, true);
                firebase.setCurrentDirectory(newDocId);
                firebase.setDocumentId(newDocId);
                const projectData = await firebase.methods.getSingleProjectData(newDocId);
                firebase.setCurrentDirectoryProjectData(projectData);
                firebase.setUploadPage(1);
                firebase.setShowProjectList(false);
            } catch (err) {
                console.error(err);
                setError('There was an error creating the project.');
                firebase.setLoading(false);
                return;
            }
        }
        const numProjectsLeft = await firebase.methods.getProjectsRemaining();
        firebase.setProjectsRemaining(numProjectsLeft);
        firebase.setLoading(false);
        navContext.setOpenNavMenu(false);
    }

    const handleMakeACopy = async (title) => {
        firebase.setLoading('file-list-item-make-a-copy');
        if (firebase.projectsRemaining <= 0) {
            setError('You have 0 projects remaining. Delete an old project to make a new project.');
            firebase.setLoading(false);
            return;
        }
        setError(null);
        const errors = await firebase.methods.titleErrors(title);
        if (errors) {
            setError(errors);
            firebase.setLoading(false);
            return;
        }
        if (firebase.currentUser) {
            try {
                const newDocId = await firebase.methods.saveProject(false, title);
                firebase.setCurrentDirectory(newDocId);
                firebase.setDocumentId(newDocId);
                const projectData = await firebase.methods.getSingleProjectData(newDocId);
                firebase.setCurrentDirectoryProjectData(projectData);
                firebase.setUploadPage(1);
                firebase.setShowProjectList(false);
            } catch (err) {
                console.error(err);
                setError('There was an error creating the project.');
                firebase.setLoading(false);
                return;
            }
        }
        const numProjectsLeft = await firebase.methods.getProjectsRemaining();
        firebase.setProjectsRemaining(numProjectsLeft);
        firebase.setLoading(false);
        navContext.setOpenNavMenu(false);
    }

    useEffect(() => {
        if (navContext.openNavMenu !== 'File' && error) {
            setError(null);
        }
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        if (firebase.documentId) {
            firebase.methods.handleSaveProject(firebase.documentId);
        }
    }, [shapesContext, firebase]);

    useHotkeys('command+s', (e) => {
        e.preventDefault();
        if (firebase.documentId) {
            firebase.methods.handleSaveProject(firebase.documentId);
        }
    }, [shapesContext, firebase]);

    const replaceStyles = shapesContext.replaceStyles;
    const canUndoAction = replaceStyles.length > 1 && replaceStyles[0][0].styleObj && replaceStyles[0][1].styleObj;

    return (
        <div className="File">
            <ListItemWithSubmenu label="New project">
                <SubmenuInput confirmLabel="Create Project" onConfirm={handleNewProject} inputLabelLeft="Project Title" inputLabelRight={`${firebase.projectsRemaining}/50 Projects Remaining`} onKeyDown={handleInputKeyDown} maxLength={25}>
                    {error &&
                        <ErrorMessage message={error} />
                    }
                    {firebase.loading === 'file-list-item-new-project' &&
                        <Loading />
                    }
                </SubmenuInput>
            </ListItemWithSubmenu>
            <ListItemWithSubmenu label="Make a copy">
                <SubmenuInput confirmLabel="Make copy" onConfirm={handleMakeACopy} inputLabelLeft="Project Title" inputLabelRight={`${firebase.projectsRemaining}/50 Projects Remaining`} onKeyDown={handleInputKeyDown} maxLength={25}>
                    {error &&
                        <ErrorMessage message={error} />
                    }
                    {firebase.loading === 'file-list-item-make-a-copy' &&
                        <Loading />
                    }
                </SubmenuInput>
            </ListItemWithSubmenu>
            {firebase.documentId &&
                <div className="file-list-item" onClick={() => firebase.methods.handleSaveProject(firebase.documentId)}>
                    <span>Save project</span>
                    {firebase.completedMessage === 'file-list-item-save-project' &&
                        <CompletedMessage message="Saved" />
                    }
                    {firebase.loading === 'file-list-item-save-project' &&
                        <Loading />
                    }
                </div>
            }
            {canUndoAction &&
                <div className="file-list-item" onClick={shapesContext.undoReplaceStyles}>
                    <span>Undo Style Action</span>
                </div>
            }
            <ListItemWithSubmenu label="Download">
                <DownloadProject />
            </ListItemWithSubmenu>
        </div>
    )
}

export default File;