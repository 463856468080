import { useState } from 'react';

const SubmenuInput = ({onConfirm, confirmLabel, inputLabel, children, onKeyDown, keepInputValue, inputLabelLeft, inputLabelRight, placeholder, className, maxLength}) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleClick = () => {
        onConfirm(inputValue);
        if (!keepInputValue) {
            setInputValue('');
        }
    }

    return (
        <div className={`SubmenuInput ${className || ''}`}>
            <div className="submenu-input-label">
                {inputLabelLeft &&
                    <div className="submenu-input-label-left">{inputLabelLeft}</div>
                }
                {inputLabel}
                {inputLabelRight &&
                    <div className="submenu-input-label-right">{inputLabelRight}</div>
                }
            </div>
            <div className="submenu-input-button-input">
                <input type="text" className="submenu-input-area" value={inputValue} placeholder={placeholder} onChange={handleChange} onKeyDown={onKeyDown} maxLength={maxLength} />
                <div className="button-wrapper">
                    <button onClick={handleClick}>{confirmLabel}</button>
                </div>
            </div>
            {children && children.filter(child => child).length > 0 &&
                <div className="submenu-input-children-wrapper">
                    {children}
                </div>
            }
        </div>
    )
}

export default SubmenuInput;