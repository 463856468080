import { createContext, useState, useEffect, useContext } from 'react';
import { ShapesContext } from '../context/ShapesContext';
import NotLoggedIn from '../NotLoggedIn';
import Loading from '../Loading';
import useLocalStorage from '../hooks/useLocalStorage';
import clone from '../../utils/_clone';
import app from 'firebase/app';
import WebFont from 'webfontloader';
import ID from '../../utils/_id';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

const FirebaseContext = createContext(null);
export { FirebaseContext }

const uploadsPerPage = 8;
export { uploadsPerPage }

if (!app.apps.length) {
    app.initializeApp({
        apiKey: "AIzaSyBcszi3G-6EQVNKYYlPFMf6l0Es-yn8Vx0",
        authDomain: "css-mockup.firebaseapp.com",
        projectId: "css-mockup",
        storageBucket: "css-mockup.appspot.com",
        messagingSenderId: "50832125160",
        appId: "1:50832125160:web:4c9f8c1791506517bced5d",
        measurementId: "G-9ZDK8NGM4Y"
    });
}
app.analytics();
const db = app.firestore();
const storage = app.storage();
const functions = app.functions();


const FirebaseApp = ({children}) => {
    const shapesContext = useContext(ShapesContext);
    const [currentUser, setCurrentUser] = useState(null);
    const [userId, setUserId] = useLocalStorage('user-id', '');
    const [listOfProjects, setListOfProjects] = useState([]);

    const [documentId, setDocumentId] = useLocalStorage('doc-id', null);
    const [projectName, setProjectName] = useLocalStorage('project-name', 'Untitled');
    const [currentProjectData, setCurrentProjectData] = useState(null);

    const [currentDirectory, setCurrentDirectory] = useState(null);
    const [currentDirectoryProjectData, setCurrentDirectoryProjectData] = useState(null);
    const [currentDirectoryUploadData, setCurrentDirectoryUploadData] = useState([]);

    const [uploadsRemaining, setUploadsRemaining] = useState(0);
    const [projectsRemaining, setProjectsRemaining] = useState(0);

    const [tempImageData, setTempImageData] = useState([]);
    const [loading, setLoading] = useState(null);
    const [loadingAuth, setLoadingAuth] = useState(true);
    const [completedMessage, setCompletedMessage] = useState(null);

    const [imagesLoaded, setImagesLoaded] = useLocalStorage('images-loaded', []);

    const [showProjectList, setShowProjectList] = useState(false);
    const [uploadPage, setUploadPage] = useState(1);

    const methods = {
        user: currentUser,
        projectCollectionRef: () => methods.user ? db.collection('user').doc(methods.user.uid).collection('projects') : null,
        singleProjectRef: (docId) => methods.projectCollectionRef().doc(docId),
        singleImageRef: (name) => methods.user ? storage.ref(`user/${methods.user.uid}/images/${name}`) : null,
        projectMetaRef: () => methods.user ? db.collection('user').doc(methods.user.uid).collection('meta').doc('project-meta') : null,
        storageMetaRef: () => methods.user ? db.collection('user').doc(methods.user.uid).collection('meta').doc('storage-meta') : null,
        imagesFolderRef: () => methods.user ? storage.ref(`user/${methods.user.uid}/images`) : null,
        userRef: () => methods.user ? db.collection('user').doc(methods.user.uid) : null,
        //
        // Resets all state data
        //
        resetAllState: () => {
            setListOfProjects([]);
            setDocumentId(null);
            setProjectName('Untitled');
            setCurrentProjectData(null);
            setCurrentDirectory(null);
            setCurrentDirectoryProjectData(null);
            setCurrentDirectoryUploadData([]);
            setUploadsRemaining(0);
            setProjectsRemaining(0);
            setTempImageData([]);
            setCompletedMessage(null);
            setImagesLoaded([]);
            setShowProjectList(false);
            setUploadPage(1);
            setUserId('');
        },
        //
        // Gets number of uploads remaining
        //
        getUploadsRemaining: async () => {
            const allImages = await methods.imagesFolderRef().listAll();
            const numImages = allImages.items.length;
            // Update the meta value so they're always in sync
            const storageMetaRef = methods.storageMetaRef();
            if (storageMetaRef) {
                await methods.storageMetaRef().set({numUploads: numImages});
                return 50 - numImages;
            } else {
                return 0;
            }
        },
        //
        // Gets number of projects remaining
        //
        getProjectsRemaining: async () => {
            const allProjectsSnapshot = await methods.projectCollectionRef().get();
            const numProjects = allProjectsSnapshot.size;
            return 50 - numProjects;
        },
        //
        // Adds a new image to imagesLoaded
        //
        addImageLoaded: (imageURL, imageName) => {
            setImagesLoaded(oldImages => {
                const newImages = [...oldImages];
                newImages.push({
                    backgroundImage: imageURL,
                    backgroundImageName: imageName
                });
                return newImages;
            });
        },
        //
        // Adds an array of new images to imagesLoaded
        //
        addImagesLoaded: (array) => {
            setImagesLoaded(oldImages => {
                const newImages = [...oldImages];
                return newImages.concat(array);
            });
        },
        //
        // Get data for a single project
        //
        getSingleProjectData: async (documentId) => {
            const docRef = methods.singleProjectRef(documentId);
            const rawData = await docRef.get();
            const data = rawData.data();
            return data || {uploads: []};
        },
        //
        // Get image and thumbnail URLs from project upload data
        //
        getImagesFromUploadData: async (uploadData, documentId = null) => {
            const formattedArray = [];
            const imagesToAdd = [];
            const imagesToRemove = [];
            for (let item of uploadData) {
                const itemStorageRef = methods.singleImageRef(item.name);
                const isLoaded = imagesLoaded.find(img => img.backgroundImageName === item.name);
                const thumbnailIsLoaded = imagesLoaded.find(img => img.backgroundImageName === item.name + '_200x200');
                let url = null;
                let thumbnailURL = null;
                try {
                    if (isLoaded) {
                        url = isLoaded.backgroundImage;
                    } else {
                        url = await itemStorageRef.getDownloadURL();
                        imagesToAdd.push({backgroundImage: url, backgroundImageName: item.name});
                    }
                    if (item.thumbnailPath) {
                        if (thumbnailIsLoaded) {
                            thumbnailURL = thumbnailIsLoaded.backgroundImage;
                        } else {
                            const thumbnailRef = storage.ref(item.thumbnailPath);
                            thumbnailURL = await thumbnailRef.getDownloadURL();
                            imagesToAdd.push({backgroundImage: thumbnailURL, backgroundImageName: item.name + "_200x200"});
                        }
                    }
                    formattedArray.push({
                        name: item.name, 
                        url,
                        currentDirectory: item.currentDirectory, 
                        thumbnailPath: item.thumbnailPath, 
                        thumbnailURL
                    });
                } catch (err) {
                    // Default thumbnail if no thumbnail found
                    formattedArray.push({
                        name: item.name,
                        url,
                        currentDirectory: item.currentDirectory,
                        thumbnailPath: item.thumbnailPath,
                        thumbnailURL: '/ultralogosymbol-white.svg'
                    });
                    console.error('error', err);
                }
            }
            methods.addImagesLoaded(imagesToAdd);
            return formattedArray;
        },
        //
        // Get a list of all user projects
        //
        getListOfProjects: async () => {
            try {
                const rawData = await methods.projectMetaRef().get();
                const data = rawData.data();
                if (!data) return [];
                if (data.projectList) {
                    return data.projectList;
                } else {
                    return [];
                }
            } catch (err) {
                console.error(err);
                return [];
            }
        },
        //
        // Save a project
        //
        saveProject: async (documentId = false, title = null, isNewProject = false) => {
            if (!documentId) {
                const projectsRemaining = await methods.getProjectsRemaining();
                if (projectsRemaining < 1) return;
            }
            console.log('saving at', new Date());
            const currentUser = methods.user;
            let projectName = ID();
            if (!documentId) {
                try {
                    const projectNameLS = JSON.parse(localStorage.getItem('project-name'));
                    projectName = projectNameLS || ID();
                } catch (err) {
                    projectName = ID();
                }
            } else {
                try {
                    projectName = JSON.parse(localStorage.getItem('project-name'));
                } catch (err) {
                    console.error(err);
                    projectName = ID();
                }
            }
            if (title) {
                projectName = title;
            }
            const shapes = shapesContext.shapes || [];
            const styles = shapesContext.styles || [];
            const individualStyles = shapesContext.individualStyles || [];
            const backgroundImages = shapesContext.backgroundImages || [];
            const individualBackgroundImages = shapesContext.individualBackgroundImages || [];
            const fonts = shapesContext.importedFonts || [];
            const codeEditor = shapesContext.codeEditorValue || '';
            const variables = shapesContext.variables || '';
            const dataObject = {
                title: projectName,
                shapes,
                styles,
                variables,
                individualStyles,
                fonts,
                codeEditor,
                backgroundImages,
                individualBackgroundImages
            };

            const emptyDataObject = {
                title: projectName,
                shapes: [],
                styles: [],
                variables: `$flex = "display: flex";`,
                individualStyles: [],
                fons: [],
                codeEditor: '',
                backgroundImages: [],
                individualBackgroundImages: [],
                uploads: []
            }
            
            if (currentUser) {
                if (documentId) {
                    try {
                        await methods.singleProjectRef(documentId).update(dataObject);
                        setCurrentProjectData(dataObject);
                        if (currentDirectory === documentId) {
                            try {
                                const newCurrentDirectoryData = await methods.getSingleProjectData(documentId);
                                setCurrentDirectoryProjectData(newCurrentDirectoryData);
                            } catch (err) {
                                console.error('error updated current directory data', err);
                            }
                        }
                        await methods.updateProjectListMeta(projectName, documentId);
                        const listOfProjects = await methods.getListOfProjects();
                        setListOfProjects(listOfProjects);
                        if (!currentDirectory) {
                            setCurrentDirectory(documentId);
                            setCurrentDirectoryProjectData(dataObject);
                        }
                        return documentId;
                    } catch (err) {
                        console.error('error saving', err);
                        return null;
                    }
                } else {
                    try {
                        dataObject.uploads = [];
                        const dataToAdd = isNewProject ? emptyDataObject : dataObject;
                        const savedDocument = await methods.projectCollectionRef().add(dataToAdd);
                        setDocumentId(savedDocument.id);
                        setCurrentProjectData(dataToAdd);
                        setProjectName(projectName);
                        setListOfProjects(oldListOfProjects => {
                            const newListOfProjects = clone(oldListOfProjects);
                            newListOfProjects.push({id: savedDocument.id, title: projectName});
                            return newListOfProjects;
                        });
                        await methods.updateProjectListMeta(projectName, savedDocument.id);
                        if (!currentDirectory) {
                            setCurrentDirectory(savedDocument.id);
                            setCurrentDirectoryProjectData(dataToAdd);
                        }
                        return savedDocument.id;
                    } catch (err) {
                        console.error('error saving', err);
                        return null;
                    }
                }
            } else return null;
        },
        //
        // Saves project with loading indicator
        //
        handleSaveProject: async (docId) => {
            setLoading('file-list-item-save-project');
            await methods.saveProject(docId);
            setLoading(false);
            setCompletedMessage('file-list-item-save-project');
        },
        //
        // Update the project list meta
        //
        updateProjectListMeta: async (projectTitle, projectId) => {
            const metaRef = methods.projectMetaRef();
            
            const doesProjectExist_internal = async () => {
                // Internal function so I don't have to waste resources running the separate function
                const rawData = await metaRef.get();
                const data = rawData.data();
                if (data && data.projectList && data.projectList.findIndex(item => item.id === projectId) > -1) {
                    return data.projectList;
                }
                return false;
            }
        
            const projectExists = await doesProjectExist_internal();
            const projectListItem = projectExists ? projectExists.find(item => item.id === projectId) : null;
        
            const updateProjectList = async () => {
                if (projectListItem) {
                    // Update title
                    if (projectListItem.title !== projectTitle) {
                        projectListItem.title = projectTitle;
                        await metaRef.set({
                            projectList: projectExists
                        }, {merge: true});
                    }
                } else {
                    await metaRef.set({
                        projectList: app.firestore.FieldValue.arrayUnion({title: projectTitle, id: projectId})
                    }, {merge: true});
                }
            }
            if (!projectExists) {
                // Project doesn't exist, so add it to the project list meta
                try {
                    await updateProjectList();
                } catch (err) {
                    console.error(err);
                }
            } else {
                try {
                    await updateProjectList();
                } catch (err) {
                    console.error(err);
                }
            }
        },
        //
        // Creates a new project
        //
        newProject: async (projectName) => {
            setCurrentDirectoryUploadData([]);
            setCurrentDirectoryProjectData(null);
            // setCurrentDirectory(null);
            // setDocumentId(null);
            setProjectName(projectName);
        
            shapesContext.setShapes([]);
            shapesContext.setStyles([]);
            shapesContext.setIndividualStyles([]);
            shapesContext.setVariables('$flex = "display: flex";');
            shapesContext.setImportedFonts([]);
            shapesContext.setCodeEditorValue('');
            shapesContext.setBackgroundImages([]);
            shapesContext.setIndividualBackgroundImages([]);
        },
        //
        // Loads a project into the app
        //
        loadProjectIntoApp: async (documentId) => {
            try {
                const projectData = await methods.getSingleProjectData(documentId);
                const currentDirectoryUploadItems = await methods.getImagesFromUploadData(projectData.uploads.slice(0).reverse().slice(0, 5), documentId);
        
                setCurrentDirectoryUploadData(currentDirectoryUploadItems);
                setCurrentDirectoryProjectData(projectData);
                setCurrentDirectory(documentId);
                setDocumentId(documentId);
                setProjectName(projectData.title);
                setUploadPage(1);
                setShowProjectList(false);

                const newBackgroundImages = await methods.generateNewBackgroundImagesList(projectData.backgroundImages);
                const newIndividualBackgroundImages = await methods.generateNewBackgroundImagesList(projectData.individualBackgroundImages);
                
                shapesContext.setBackgroundImages(newBackgroundImages);
                shapesContext.setIndividualBackgroundImages(newIndividualBackgroundImages);
                shapesContext.setShapes(projectData.shapes);
                shapesContext.setStyles(projectData.styles);
                shapesContext.setIndividualStyles(projectData.individualStyles);
                shapesContext.setVariables(projectData.variables);
                shapesContext.setImportedFonts(projectData.fonts || []);
                shapesContext.setCodeEditorValue(projectData.codeEditor);

                // Load the fonts
                if (projectData.fonts && projectData.fonts.length > 0) {
                    WebFont.load({
                        google: {
                            families: projectData.fonts
                        }
                    });
                }

                setLoading(null);
        
            } catch (err) {
                console.error('could not load', documentId, err);
            }
        },
        //
        // Generates new background images list
        //
        generateNewBackgroundImagesList: async (list) => {
            const newList = clone(list);
            for (let imageObject of newList) {
                if (imageObject.backgroundImageName) {
                    const imageRef = methods.singleImageRef(imageObject.backgroundImageName);
                    try {
                        const itemDownloadURL = await imageRef.getDownloadURL();
                        imageObject.backgroundImage = itemDownloadURL;
                    } catch (err) {
                        console.error('error loading new image', err, imageObject);
                    }
                }
            }
            return newList;
        },
        //
        // Updates the project's list of uploads
        //
        updateProjectListOfUploads: async (documentId, uploadItemString) => {
            const docRef = methods.singleProjectRef(documentId);
            await docRef.update({
                uploads: app.firestore.FieldValue.arrayUnion(uploadItemString)
            });
        },
        //
        // Move an upload from one project to another
        //
        moveUpload: async (toProject, uploadName) => {
            const fromProject = currentDirectory;
        
            if (currentDirectoryUploadData.length === 0) return;
        
            const newFromUploadData = currentDirectoryUploadData.map(item => ({
                name: item.name,
                currentDirectory: item.currentDirectory,
                thumbnailPath: item.thumbnailPath
            }));
            const removingItemIndex = newFromUploadData.findIndex(item => item.name === uploadName);
            try {
                const removingItemClone = {...newFromUploadData[removingItemIndex]};
                newFromUploadData.splice(removingItemIndex, 1);
        
                // Update the from project contents
                await methods.singleProjectRef(fromProject).update({
                    uploads: newFromUploadData
                });
        
                // Update the information of the destination folder
                await methods.singleProjectRef(toProject).update({
                    uploads: app.firestore.FieldValue.arrayUnion(removingItemClone)
                });
        
                // Update upload documentId metadata
                await methods.singleImageRef(uploadName).updateMetadata({
                    customMetadata: {
                        documentId: toProject
                    }
                });
        
                // Update firebase state
                setCurrentDirectoryProjectData(oldData => {
                    const newData = clone(oldData);
                    const indexOfRemovingItem = newData.uploads.findIndex(item => item.name === uploadName);
                    newData.uploads.splice(indexOfRemovingItem, 1);
                    return newData;
                });
        
                // Update firebase state
                setCurrentDirectoryUploadData(oldData => {
                    const newData = clone(oldData);
                    const indexOfRemovingItem = newData.findIndex(item => item.name === uploadName);
                    newData.splice(indexOfRemovingItem, 1);
                    return newData;
                });
        
            } catch (err) {
                console.error('error moving', err);
            }
        },
        //
        // Deletes a project
        //
        deleteProject: async (documentId) => {

            const docRef = methods.singleProjectRef(documentId);
            // Get the data of the document so we can access its upload directory
            const rawData = await docRef.get();
            const docData = rawData.data();
        
            const uploads = docData?.uploads || [];
            for (let upload of uploads) {
                const itemStorageRef = methods.singleImageRef(upload.name);
                try {
                    await itemStorageRef.delete();
                } catch (err) {
                    console.error('image delete error', err);
                }
            }
            try {
                await docRef.delete();
                // NEW METHOD
                await methods.deleteProjectMetaListItem(documentId);
            } catch (err) {
                console.error('doc delete error', err);
            }
            // Get number of uploads remaining
            const numUploadsLeft = await methods.getUploadsRemaining();
            setUploadsRemaining(numUploadsLeft);

            // Get number of projects remaining
            const numProjectsLeft = await methods.getProjectsRemaining();
            setProjectsRemaining(numProjectsLeft);

            // Remove project from list of projects
            setListOfProjects(oldList => {
                const newList = clone(oldList);
                const currentDocument = newList.findIndex(item => item.id === documentId);
                if (currentDocument !== -1) {
                    newList.splice(currentDocument, 1);
                }
                return newList;
            });
        },
        //
        // Deletes a project from the meta data of project lists
        //
        deleteProjectMetaListItem: async (projectId) => {
            const metaRef = methods.projectMetaRef();
            const rawData = await metaRef.get();
            const data = rawData.data();
            if (data && data.projectList) {
                const projectListItemIndex = data.projectList.findIndex(item => item.id === projectId);
                if (projectListItemIndex > -1) {
                    data.projectList.splice(projectListItemIndex, 1);
                }
                try {
                    await metaRef.set({
                        projectList: data.projectList
                    }, {merge: true});
                } catch (err) {
                    console.error(err);
                    return null;
                }
            }
        },
        //
        // Deletes an upload
        //
        deleteUpload: async (uploadName) => {
            // Need to delete from storage and the project data
            const currentDirectoryUploadData = currentDirectoryProjectData.uploads;
        
            if (!currentDirectory) return;
            if (!currentDirectoryUploadData) return;
        
            const uploadDataClone = clone(currentDirectoryUploadData);
            const itemIndexInDirectory = uploadDataClone.findIndex(item => item.name === uploadName);
        
            if (itemIndexInDirectory > -1) {
                uploadDataClone.splice(itemIndexInDirectory, 1);
            }
        
            const itemDocRef = methods.singleProjectRef(currentDirectory);
            await itemDocRef.update({
                uploads: uploadDataClone
            });
        
        
            // Delete the item in storage
            try {
                await methods.singleImageRef(uploadName).delete();
            } catch (err) {
                console.error(err);
                // Still proceed with the function to delete the other data
            }

            // Get number of uploads remaining
            const numUploadsLeft = await methods.getUploadsRemaining();
            setUploadsRemaining(numUploadsLeft);
        
            // Update firebase context state from new data
        
            const spliceUploads = (oldData, isUploadData = false) => {
                const newData = clone(oldData);
                const obj = (isUploadData) ? newData : newData.uploads;
                const uploadItem = obj.findIndex(item => item.name === uploadName);
                if (uploadItem > -1) {
                    obj.splice(uploadItem, 1);
                }
                return newData;
            }
        
            if (documentId === currentDirectory) {
                setCurrentProjectData(oldData => spliceUploads(oldData));
            }
            setCurrentDirectoryProjectData(oldData => spliceUploads(oldData));
            setCurrentDirectoryUploadData(oldData => spliceUploads(oldData, true));
            setTempImageData(oldData => spliceUploads(oldData, true));
        },
        //
        // Updates the fonts
        //
        updateFonts: async (updatedList) => {
            const docRef = methods.singleProjectRef(documentId);
            await docRef.update({
                fonts: updatedList
            });
            const newRawData = await docRef.get();
            const newDocData = newRawData.data();
            setCurrentProjectData(newDocData);
        },
        //
        // Gets the directory information of an uploaded image
        //
        getDirectoryOfImage: async (uploadName) => {
            try {
                const metadata = await methods.singleImageRef(uploadName).getMetadata();
                return metadata;
            } catch (err) {
                console.error(err);
                return {};
            }
        },
        //
        // Checks if a project title exists
        //
        doesProjectTitleExist: async (projectTitle) => {
            const rawData = await methods.projectMetaRef().get();
            const data = rawData.data();
            if (data && data.projectList && data.projectList.findIndex(item => item.title === projectTitle) > -1) {
                return true;
            }
            return false;
        },
        //
        // Renames a project
        //
        renameProject: async (documentId, newTitle) => {
            const docRef = methods.singleProjectRef(documentId);
            const errors = await methods.titleErrors(newTitle);
            if (errors) {
                return errors;
            } else {
                try {
                    await docRef.update({title: newTitle});
                } catch (err) {
                    return 'Error saving project rename.';
                }
                // Rename project in list of projects
                setListOfProjects(oldList => {
                    const newList = clone(oldList);
                    const currentDocument = newList.find(item => item.id === documentId);
                    if (currentDocument) {
                        currentDocument.title = newTitle;
                    }
                    return newList;
                });
                return false;
            }
        },
        //
        // Checks for title errors
        //
        titleErrors: async (title) => {
            // No title
            if (!title) {
                return 'Error: Enter a project title.';
            }
            // Title is too long
            if (title.length > 25) {
                return 'Error: Title must be 25 characters or less.';
            }
    
            // Check if title already exists
            const projectTitleExists = await methods.doesProjectTitleExist(title);
            if (projectTitleExists) {
                return 'Error: Project title already exists.';
            }
            return false;
        }
    }

    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged(async (user) => {
            let activeDocumentId = documentId;
            setLoadingAuth(false);
            setCurrentUser(user);
            let localUserId = null;
            try {
                localUserId = JSON.parse(localStorage.getItem('user-id'));
            } catch (err) {
                console.error(err);
                localUserId = null;
            }
            if (user && localUserId && user.uid !== localUserId) {
                localStorage.clear();
                methods.resetAllState();
                shapesContext.resetAllShapes();
                activeDocumentId = null;
            }
            if (user) setUserId(user.uid);
            methods.user = user;
            if (user) {
                const listOfProjectsData = await methods.getListOfProjects();
                if (activeDocumentId) {
                    //await methods.saveProject(documentId);
                    //console.log('saving project at', new Date());
                    try {
                        await methods.loadProjectIntoApp(activeDocumentId);
                        const singleProjectData = await methods.getSingleProjectData(activeDocumentId);
                        const currentDirectoryUploadItems = await methods.getImagesFromUploadData(singleProjectData.uploads.slice(0).reverse().slice(0, uploadsPerPage), documentId);
                        setCurrentDirectoryUploadData(currentDirectoryUploadItems);
                        setCurrentProjectData(singleProjectData);
                        setCurrentDirectory(documentId, singleProjectData);
                        setCurrentDirectoryProjectData(singleProjectData);
                    } catch (err) {
                        console.error(err);
                    }
                }
                setListOfProjects(listOfProjectsData);
                const numUploadsLeft = await methods.getUploadsRemaining();
                setUploadsRemaining(numUploadsLeft);
                const numProjectsLeftAfter = await methods.getProjectsRemaining();
                setProjectsRemaining(numProjectsLeftAfter);
            } else {
                methods.resetAllState();
                shapesContext.resetAllShapes();
            }
        });
        return () => unsubscribe();
    }, [setCurrentUser]);

    return (
        <FirebaseContext.Provider value={{
            app, db, storage, functions,
            currentUser, setCurrentUser,
            listOfProjects, setListOfProjects,
            documentId, setDocumentId,
            projectName, setProjectName,
            currentProjectData, setCurrentProjectData,
            currentDirectory, setCurrentDirectory,
            currentDirectoryProjectData, setCurrentDirectoryProjectData,
            currentDirectoryUploadData, setCurrentDirectoryUploadData,
            tempImageData, setTempImageData,
            loading, setLoading,
            completedMessage, setCompletedMessage,
            showProjectList, setShowProjectList,
            uploadPage, setUploadPage,
            imagesLoaded, setImagesLoaded,
            uploadsRemaining, setUploadsRemaining,
            projectsRemaining, setProjectsRemaining,
            methods
        }}>
            {currentUser && !loadingAuth && children}
            {loadingAuth &&
                <div className="big-loading">
                    <Loading />
                </div>
            }
            {!currentUser && !loadingAuth &&
                <NotLoggedIn />
            }
        </FirebaseContext.Provider>
    )
}

export default FirebaseApp;