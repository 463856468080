import { useRef, useContext, useEffect } from 'react';
import { ShapesContext } from './context/ShapesContext';
import { FirebaseContext } from './context/FirebaseContext';
import enableTab, { pressEnter, pressBackspace } from '../utils/_enable-tab-textarea';
import ReactHtmlParser from 'react-html-parser';
import spliceString from '../utils/_splice-string';
import { 
    getLineInstanceInShapes, 
    numberOfTabs, 
    findLineSelected,
    findSingleRawLineSelected,
    addPipesAtEachTab, 
    findLineParent,
    backwardsNumberOfTabs
} from '../utils/_code-editor';

const acceptableCharacters = /^[a-zA-Z-_.]+$/;

const CodeEditor = ({value, onChange}) => {
    const overlay = useRef(null);
    const textArea = useRef(null);
    const shapesContext = useContext(ShapesContext);
    const firebase = useContext(FirebaseContext);
    const lineSelected = shapesContext.lineSelected;

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const tabSelection = (selection, addRemove) => {
        const splitSelection = selection.split('\n');
        let newSelection = '';
        if (addRemove === "add") {
            newSelection = splitSelection.map(line => '\t' + line).join('\n');
        } else {
            newSelection = splitSelection.map(line => line.replace('\t', '')).join('\n');
        }
        return newSelection;
    }


    const handleKeyDown = async (e) => {
        if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            firebase.methods.handleSaveProject(firebase.documentId);
            return;
        }
        const selectedLine = findSingleRawLineSelected(e);
        const allLinesSelected = findLineSelected(e);
        const windowSelection = window.getSelection();
        const selection = windowSelection.toString();
        // Removes the right tabs if there are any from the line
        if (selectedLine && !windowSelection) {
            const backwardsTabs = backwardsNumberOfTabs(selectedLine.value);
            if (backwardsTabs > 0 && selectedLine.value.trim()) {
                e.target.value = spliceString(e.target.value, selectedLine.to - backwardsTabs, backwardsTabs);
            }
        }
        if (e.key === "Tab") {
            // Prevent tabbing after the class
            if (e.target.value[e.target.selectionEnd - 1].match(acceptableCharacters) && !selection) {
                e.preventDefault();
            } else {
                if (selection.length > 0) {
                    e.preventDefault();
                    const beforeSelection = e.target.value.substring(0, e.target.selectionStart);
                    const afterSelection = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
                    if (!e.shiftKey) {
                        // Add tabs to selection
                        const insertedTabs = tabSelection(selection, "add");
                        const newSelection = [e.target.selectionStart, e.target.selectionStart + insertedTabs.length];
                        e.target.value = beforeSelection + insertedTabs + afterSelection;
                        e.target.selectionStart = newSelection[0];
                        e.target.selectionEnd = newSelection[1];
                    } else {
                        // Remove tabs from selection
                        const insertedTabs = tabSelection(selection, "remove");
                        const newSelection = [e.target.selectionStart, e.target.selectionStart + insertedTabs.length];
                        e.target.value = beforeSelection + insertedTabs + afterSelection;
                        e.target.selectionStart = newSelection[0];
                        e.target.selectionEnd = newSelection[1];
                    }
                    onChange(e);
                } else {
                    enableTab(e);
                    onChange(e);
                }
            }
        }
        const firstLine = lineSelected.length > 0 ? lineSelected[0] : {index: null};
        // Place tab at correct spot on press enter
        if (e.key === "Enter" && firstLine.index) {
            const numTabsOnLineSelected = numberOfTabs(firstLine.value);
            if (numTabsOnLineSelected > 0) {
                pressEnter(e);
            }
            let tabbed = 0;
            while (tabbed < numTabsOnLineSelected) {
                enableTab(e);
                tabbed++;
            }
            onChange(e);
        }
        // PASTE - Adjust tabbing so it pastes in the correct spot
        if (e.key === "v" && (e.metaKey || e.ctrlKey) && selectedLine.index) {
            const numTabsOnLineSelected = numberOfTabs(selectedLine.value);
            let tabbed = 0;
            while (tabbed < numTabsOnLineSelected) {
                pressBackspace(e);
                tabbed++;
            }
            onChange(e);
        }
        const matchCharacter = e.key.match(acceptableCharacters);
        if (!matchCharacter) {
            e.preventDefault();
            return;
        }
        shapesContext.setLineSelected(allLinesSelected);
    }

    const handleKeyUp = async (e) => {
        await timeout(0);
        shapesContext.setLineSelected(findLineSelected(e));
    }

    const handleSelect = async (e) => {
        await timeout(0);
        shapesContext.setLineSelected(findLineSelected(e));
    }

    const handleTextAreaMouseDown = async (e) => {
        await timeout(0);
        if (shapesContext.useDifferentStyles) shapesContext.setUseDifferentStyles(null);
        shapesContext.setLineSelected(findLineSelected(e));
    }

    const handleTextareaScroll = (e) => {
        overlay.current.style.top = -e.target.scrollTop + "px";
        overlay.current.style.left = -e.target.scrollLeft + "px";
    }

    const splitValue = value.split('\n');

    useEffect(() => {
        overlay.current.style.top = -textArea.current.scrollTop + "px";
        overlay.current.style.left = -textArea.current.scrollLeft + "px";
    });

    return (
        <div className="CodeEditor">
            <textarea 
                className="code-editor-textarea" 
                onScroll={handleTextareaScroll} 
                value={value} 
                onMouseDown={handleTextAreaMouseDown}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onChange={onChange}
                onSelect={handleSelect}
                ref={textArea}
                spellCheck="false"
            ></textarea>
            <div className="textarea-overlay"  ref={overlay}>
            {splitValue.map((line, index) => {
                const indexOfClassId = getLineInstanceInShapes(line, index, shapesContext, splitValue);
                const lineStyle = shapesContext.styles.find(item => item.classId === line.trim());
                const lineColor = (lineStyle) ? lineStyle.lineColor : '';
                const lineWithPipe = addPipesAtEachTab(line);
                const parent = findLineParent(splitValue, index);
                const parentInstance = getLineInstanceInShapes(parent.value, parent.index, shapesContext, splitValue) + 1;
                const isSelected = lineSelected.find(item => item.index === index);
                return (
                    <pre
                        className={`single-line ${isSelected ? 'selected-line' : ''}`} 
                        style={(lineColor) ? {color: lineColor} : {color: '#000'}}
                        key={index}
                        >
                        {ReactHtmlParser(lineWithPipe)}{(indexOfClassId > -1) ? <em>.{indexOfClassId + 1}</em> : ''}<span className="subtle-pre-text pre-line-parent">{(isSelected && parent.value) ? `parent: ${parent.value.trim()}.${parentInstance}` : ''}</span>
                    </pre>
                )
                })}
            </div>
        </div>
    )
}

export default CodeEditor;